import React, { useCallback, useEffect, useState } from "react";

import _ from "lodash";
import {
  Button as MUIButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  ImageList,
  ImageListItem,
} from "@material-ui/core";

import { getReturns, publishReturns, updateReturn } from "api/shipment";

import { Spacer, StatusLabel } from "components/Custom";
import { ExternalLink } from "components/Custom/Links";
import { Button, Checkbox, Dropdown, InputContainer } from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";

import {
  Class,
  Edit,
  FolderSpecial,
  LocalShipping,
  Person,
  QrCode2,
  Photograph,
} from "icons";

import { getCarrierTrackingLink } from "pages/shipments/utils";

import { ACUMATICA_BASE_URL, CONDITIONS, RETURN_URL } from "utils/constants";
import { initialAPIListValue } from "utils/constants";
import { timezoneFormat } from "utils/date";
import getClearAdornment from "utils/form";

import useStyles from "./styles";
import { Link } from "react-router-dom";
import { exportToExcel } from "utils/exportToExcel";
import { convertDataToTableView } from "utils/convert";
import { useUserState } from "context/UserContext";
import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";

const headers = [
  {
    id: "customer",
    render: (r, exported) =>
      exported ? (
        r.customer
      ) : (
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={1}>
            {r.is_published ? (
              <div
                style={{
                  backgroundColor: "#00CC00",
                  width: "5px",
                  height: "30px",
                  position: "relative",
                  left: "-5px",
                  borderRadius: "3px",
                }}
              />
            ) : null}
          </Grid>
          <Grid item xs={9}>
            <span title="Customer">{r.customer}</span>
          </Grid>
          {r.details?.filter((d) => d.attachments?.length > 0).length > 0 && (
            <Grid item xs={1}>
              <span title="Attachments">
                <Photograph color="#bbbb0050" />
              </span>
            </Grid>
          )}
        </Grid>
      ),
  },
  {
    id: "product_sku",
    render: (r, exported) => {
      if (exported) {
        return r.details
          .map((d) =>
            d.product ? `${d.product.sku} - (${d.condition})` : "Not Ours",
          )
          .toString();
      }
      const itemCount = r.details.length;
      return !itemCount
        ? "-"
        : itemCount === 1
        ? r.details[0].product?.sku
        : `Multiple Items (${itemCount})`;
    },
  },
  { id: "po_number" },
  {
    id: "tracking_number",
    render: (r, exported) => {
      if (exported) {
        return r.tracking_number;
      }
      const carrier = r.tracking_number.startsWith("1Z") ? "UPS" : "FEDEX";
      return (
        <ExternalLink
          to={getCarrierTrackingLink(carrier, r.tracking_number)}
          children={r.tracking_number}
        />
      );
    },
  },
  {
    id: "return_number",
    label: "RC Number",
    render: (r, exported) => {
      if (exported) {
        return r.return_number;
      }
      return r.return_number ? (
        <ExternalLink
          to={`${ACUMATICA_BASE_URL}${RETURN_URL}${r.return_number}`}
          children={r.return_number}
        />
      ) : (
        "-"
      );
    },
  },
  { id: "note", render: (r) => r.note || "-" },
  {
    id: "condition",
    render: (r, exported) =>
      exported ? (
        r.condition
      ) : (
        <StatusLabel status={r.condition || "Not Ours"} />
      ),
  },
  { id: "created_at", render: (r) => timezoneFormat(r.created_at) },
];

const exportHeaders = [
  ...headers,
  {
    id: "is_published",
    label: "Is Published",
    render: (r) => (r.is_published ? "Published" : "Not Published"),
  },
];

export default function Returns() {
  const layoutDispatch = useLayoutDispatch();
  const [returns, setReturns] = useState(initialAPIListValue);
  const { seller_id } = parseJwt(getWithExpiry("access"));
  const [params, setParams] = useState({
    product_sku: "",
    customer: "",
    po_number: "",
    return_number: "",
    is_published: "",
    tracking_number: "",
    not_ours: "",
    seller_id: seller_id === 1 ? "" : seller_id,
    with_details: true,
  });
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [exportDisabled, setExportDisabled] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedReturn, setSelectedReturn] = useState({});
  const [detailModal, setDetailModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (returns.isFetching) {
      let filters = {
        ...params,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        paginated: true,
      };

      if (orderDirection && orderBy) {
        filters.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }

      getReturns({
        params: filters,
        setFunc: (res) => {
          setReturns({
            ...res,
            items: _.uniqBy(res.items, (i) => i.id),
          });
        },
        paginated: true,
      });
    }
  }, [params, page, rowsPerPage, returns.isFetching, orderDirection, orderBy]);

  const handleCheckBox = (prop) => () => {
    setParams((prev) => ({
      ...prev,
      [prop]: params[prop] ? "" : !params[prop],
    }));
    setPage(0);
    setReturns((prev) => ({ ...prev, isFetching: true }));
  };

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setReturns((prev) => ({ ...prev, isFetching: true }));
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
    setReturns((prev) => ({ ...prev, isFetching: true }));
  };

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    setRowsPerPage(parseInt(value, 10));
    debouncedSearchHandler();
  };

  const handleChangeFilterInput = (prop) => (event) => {
    setParams({ ...params, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const clearHandler = (input) => {
    setParams({ ...params, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, params);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    _.debounce(() => {
      setReturns({ ...returns, isFetching: true });
    }, 500),
    [],
  );

  const handleCloseModal = (status = false) => {
    if (status) {
      setReturns((prev) => ({ ...prev, isFetching: true }));
    }
    setDetailModal(false);
    setSelectedReturn({});
  };

  const handlePublish = () => {
    setIsFetching(true);
    publishReturns({
      body: {
        return_ids: selectedRows
          .filter((r) => !r.is_published)
          .map((r) => r.id),
      },
      responseSetter: (res) => {
        setIsFetching(false);
        const { success, message } = res;
        popupNotification({
          dispatch: layoutDispatch,
          message,
          status: success ? "success" : "error",
        });
        setSelectedRows([]);
        setReturns((prev) => ({ ...prev, isFetching: true }));
      },
    });
  };

  const exportData = (data) => {
    setExportDisabled(true);
    const today = new Date();
    exportToExcel(
      [],
      `Returns-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, exportHeaders),
    );
    setExportDisabled(false);
  };

  const handleExportData = () => {
    if (returns.items.length === returns.count) {
      exportData(returns.items);
    } else {
      getReturns({
        params: {
          ...params,
          limit: returns.count,
        },
        setFunc: ({ items }) => exportData(items),
        paginated: true,
      });
    }
  };

  const handleChangeCheckbox = (prop) => (event) => {
    setParams({ ...params, [prop]: event.target.checked ? 2 : null });
    debouncedSearchHandler();
  };

  return (
    <>
      <PageTitle
        title="Returns"
        button={
          seller_id === 1 && (
            <MUIButton
              component={Link}
              to="/app/create-return"
              variant="contained"
              size="medium"
              color="secondary"
            >
              Create Return
            </MUIButton>
          )
        }
      />
      <InputContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="sku"
              label="SKU"
              variant="outlined"
              value={params.product_sku}
              onChange={handleChangeFilterInput("product_sku")}
              InputProps={clearAdornment("product_sku")}
              LeftIcon={QrCode2}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="po_number"
              label="PO Number"
              variant="outlined"
              value={params.po_number}
              onChange={handleChangeFilterInput("po_number")}
              InputProps={clearAdornment("po_number")}
              LeftIcon={FolderSpecial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="return_number"
              label="RC Number"
              variant="outlined"
              value={params.rc_number}
              onChange={handleChangeFilterInput("return_number")}
              InputProps={clearAdornment("return_number")}
              LeftIcon={FolderSpecial}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="tracking_number"
              label="Tracking Number"
              variant="outlined"
              value={params.tracking_number}
              onChange={handleChangeFilterInput("tracking_number")}
              InputProps={clearAdornment("tracking_number")}
              LeftIcon={LocalShipping}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="customer"
              label="Customer"
              variant="outlined"
              value={params.customer}
              onChange={handleChangeFilterInput("customer")}
              InputProps={clearAdornment("customer")}
              LeftIcon={Person}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Dropdown
              id="is_published"
              title="Published"
              value={params.is_published_acumatica}
              onChange={handleChangeFilterInput("is_published")}
              items={[
                {
                  value: false,
                  label: "Not Published",
                },
                {
                  value: true,
                  label: "Published",
                },
              ]}
              LeftIcon={Class}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Dropdown
              id="condition"
              title="Condition"
              value={params.condition}
              onChange={handleChangeFilterInput("condition")}
              items={CONDITIONS.map((c) => ({ value: c, label: c }))}
              LeftIcon={Class}
            />
          </Grid>
          {seller_id === 1 && (
            <>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Checkbox
                  id="not_ours"
                  label="Not Ours"
                  checked={params.not_ours}
                  onChange={handleCheckBox("not_ours")}
                  color="blue"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Checkbox
                  label="OBT"
                  checked={params.seller_id}
                  onChange={handleChangeCheckbox("seller_id")}
                  popup="OBT Items"
                />
              </Grid>
              <Hidden only={["sm", "md", "xs"]}>
                <Grid item lg={seller_id === 1 ? 4 : 6} />
              </Hidden>

              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Button
                  color="blue"
                  disabled={
                    !selectedRows.length ||
                    !selectedRows.filter((r) => !r.is_published).length
                  }
                  onClick={handlePublish}
                >
                  Publish
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </InputContainer>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {isFetching ? (
          <CircularProgress />
        ) : (
          <DefaultTable
            headers={headers}
            data={returns}
            filters={params}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            sortActions={{ onSort, orderBy, orderDirection }}
            rowOnClick={(r) => {
              setSelectedReturn(r);
              setDetailModal(true);
            }}
            hasCheckBox={true}
            selected={selectedRows}
            setSelected={setSelectedRows}
            handleExportData={handleExportData}
            exportDisabled={exportDisabled}
            setExportDisabled={setExportDisabled}
          />
        )}
        {detailModal ? (
          <ReturnDetails
            seller_id={seller_id}
            open={detailModal}
            onClose={handleCloseModal}
            returnData={selectedReturn}
          />
        ) : null}
      </Grid>
    </>
  );
}

const detailHeaders = [
  {
    id: "product",
    render: (r) => (r.product ? r.product.sku : "-"),
  },
  {
    id: "condition",
    render: (r) => <StatusLabel status={r.condition || "Not Ours"} />,
  },
  {
    id: "images",
    render: (r) => {
      return !r.attachments.length ? (
        "-"
      ) : (
        <BasicImageList images={r.attachments} />
      );
    },
  },
];

function ReturnDetails({ returnData, open, onClose, seller_id }) {
  const layoutDispatch = useLayoutDispatch();
  const { permissions } = useUserState();
  const [isFetching, setIsFetching] = useState(false);
  const [formData, setFormData] = useState({
    customer: returnData.customer,
    po_number: returnData.po_number,
    tracking_number: returnData.tracking_number,
    note: returnData.note,
  });

  const hasPublishPermission = permissions?.includes("shipment.publish_return");
  const hasUpdatePermission = permissions?.includes("shipment.change_return");
  const updateDisabled = returnData.is_published || !hasUpdatePermission;

  const handleChangeFilter = (prop) => ({ target: { value } }) => {
    setFormData((prev) => ({ ...prev, [prop]: value }));
  };

  const handleUpdate = () => {
    setIsFetching(true);
    updateReturn({
      id: returnData.id,
      body: { ...formData },
      responseSetter: (res) => {
        setIsFetching(false);
        const { id } = res;
        if (!id) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: "Server Error",
          });
        }
        onClose(true);
        popupNotification({
          dispatch: layoutDispatch,
          message: "Done!",
          status: "success",
        });
      },
    });
  };

  const handlePublishReturns = () => {
    setIsFetching(true);
    publishReturns({
      body: {
        return_ids: [returnData.id],
      },
      responseSetter: (res) => {
        setIsFetching(false);
        const { success, message } = res;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }

        onClose(true);
        popupNotification({
          dispatch: layoutDispatch,
          message,
          status: "success",
        });
      },
    });
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="sm" fullWidth>
      <DialogTitle>{`${returnData.po_number}-${returnData.customer}-${returnData.tracking_number}`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormattedTextField
              fullWidth
              id="po_number"
              label="PO Number"
              variant="outlined"
              value={formData.po_number}
              onChange={handleChangeFilter("po_number")}
              disabled={updateDisabled}
              LeftIcon={FolderSpecial}
            />
          </Grid>
          <Grid item xs={6}>
            <FormattedTextField
              fullWidth
              id="customer"
              label="Customer"
              variant="outlined"
              value={formData.customer}
              onChange={handleChangeFilter("customer")}
              disabled={updateDisabled}
              LeftIcon={Person}
            />
          </Grid>
          <Grid item xs={6}>
            <FormattedTextField
              fullWidth
              id="tracking_number"
              label="Tracking Number"
              variant="outlined"
              value={formData.tracking_number}
              onChange={handleChangeFilter("tracking_number")}
              disabled={updateDisabled}
              LeftIcon={LocalShipping}
            />
          </Grid>
          <Grid item xs={6}>
            <FormattedTextField
              fullWidth
              id="note"
              label="Note"
              variant="outlined"
              value={formData.note}
              onChange={handleChangeFilter("note")}
              disabled={updateDisabled}
              LeftIcon={Edit}
            />
          </Grid>
          <Grid item xs={12}>
            {returnData.is_published ? (
              <ExternalLink
                style={{
                  padding: "1rem",
                }}
                to={`${ACUMATICA_BASE_URL}${RETURN_URL}${returnData.return_number}`}
              >
                RC Number : {returnData.return_number}
              </ExternalLink>
            ) : (
              seller_id === 1 && (
                <Button
                  color="primary"
                  onClick={handleUpdate}
                  disabled={isFetching || !hasUpdatePermission}
                >
                  Update
                </Button>
              )
            )}
          </Grid>
        </Grid>
        <Spacer height="1rem" />
        <Grid container>
          <Grid item xs={12}>
            {isFetching ? (
              <CircularProgress />
            ) : (
              <DefaultTable
                headers={detailHeaders}
                data={{ items: returnData.details, isFetching: false }}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose(false)}
          color="danger"
          disabled={isFetching}
        >
          Close
        </Button>
        {!returnData.is_published && seller_id === 1 ? (
          <Button
            color="primary"
            onClick={handlePublishReturns}
            disabled={isFetching || !hasPublishPermission}
          >
            Publish
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

function BasicImageList({ images }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ImageList rowHeight={160} className={classes.tableImageList} cols={1}>
        {images.map((item) => (
          <ImageListItem key={item.id} cols={1}>
            <img
              src={item.signed_url}
              alt={item.id}
              onClick={() => window.open(item.signed_url)}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}
