import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { refreshToken } from "api/auth";

import { setIsMobileDevice, useLayoutDispatch } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";

import DesktopNav from "./DesktopNav";
import { menuItems } from "./menuItems";
import MobileNav from "./MobileNav";

const mediaQuery = "screen and (min-width: 768px)";

function Sidebar({ location }) {
  const claims = parseJwt(getWithExpiry("access"));
  const { seller_id } = claims;
  const mql = window.matchMedia(mediaQuery);
  const [showDesktopMenu, setShowDesktopMenu] = useState(mql.matches);
  const layoutDispatch = useLayoutDispatch();
  const { permissions } = useUserState();
  const [availableItems, setAvailableItems] = useState([]);

  useEffect(() => {
    if (!claims) {
      refreshToken();
    }
  }, [claims]);

  useEffect(() => {
    const handleMediaChange = function (e) {
      setShowDesktopMenu(e.matches);
    };
    mql.addEventListener("change", handleMediaChange);
    setShowDesktopMenu(mql.matches);

    return () => {
      mql.removeEventListener("change", handleMediaChange);
    };
  }, [mql]);

  useEffect(() => {
    setIsMobileDevice(layoutDispatch, !showDesktopMenu);
  }, [showDesktopMenu, layoutDispatch]);

  useEffect(() => {
    if (permissions) {
      const { items } = menuItems();

      const availableMenuList = items.map((item) => {
        let menu = null;
        if ((permissions?.includes(item.permission) || !item.permission) && 
            (seller_id === 1 || item.seller_visible)) {
          menu = item;
          const children = item.children.map((child) => {
            return (permissions?.includes(child.permission) || !child.permission) && 
                   (seller_id === 1 || child.seller_visible)
              ? child
              : null;
          });
          menu.children = children.filter(Boolean);
          return menu.children.length > 0 ? menu : null;
        }
        return menu;
      });

      setAvailableItems(availableMenuList.filter(Boolean));
    }
  }, [permissions, seller_id]);

  if (showDesktopMenu) {
    return <DesktopNav location={location} items={availableItems} />;
  } else {
    return <MobileNav location={location} items={availableItems} />;
  }
}

export default withRouter(Sidebar);
