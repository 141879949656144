import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core";

import classNames from "classnames";

import _ from "lodash";

import { ltlShip, readyToShip } from "api/shipment";

import { ConfirmDialog, StatusLabel } from "components/Custom";
import PickStatus from "components/Custom/PickStatus";
import DefaultTable from "components/Table";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import { ArrowDropDown, DocumentDownload, Done } from "icons";

import LTLLabelsModal from "./LabelModal";
import useStyles from "./styles";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: "10rem",
    marginLeft: "2rem",
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.2)",
    " & .MuiList-padding": {
      padding: "0px",
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "center",
      horizontal: "left",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.common.white,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.black,
      },
    },
    "&:hover": {
      backgroundColor: `${theme.palette.secondary.main}20`,
    },
  },
}))(MenuItem);

export default function ActionButtons({ shipment, reloadShipments }) {
  const classes = useStyles();
  const { permissions } = useUserState();
  const layoutDispatch = useLayoutDispatch();
  const [hover, setHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [readyToShip, setReadyToShip] = useState(false);
  const [shipConfirm, setShipConfirm] = useState(false);
  const [downloadLabels, setDownloadLabels] = useState(false);
  const hasGetLabelPermission = permissions?.includes(
    "contenttypes.ltl.get_labels",
  );

  const hasReadyToShipPermission = permissions?.includes(
    "contenttypes.ltl.ready_to_ship",
  );

  const hasShipPermission = permissions?.includes("contenttypes.ltl.ship");

  const handleCloseReadyToShip = (status = false) => {
    setReadyToShip(false);
    handleClose();
    if (status) {
      reloadShipments();
    }
  };

  const handleCloseDownloadLabel = () => {
    handleClose();
    setDownloadLabels(false);
  };

  const handleLTLShip = () => {
    ltlShip({
      params: {
        shipment_number: shipment.shipment_number,
      },
      responseSetter: (res) => {
        const { success, message } = res;
        if (!success) {
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }
        reloadShipments();
        return popupNotification({
          dispatch: layoutDispatch,
          message,
          status: "success",
        });
      },
    });
  };

  const handleCloseShipConfirm = () => {
    setShipConfirm(false);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <span
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        className={classNames(classes.actionIcon, {
          [classes.actionHover]: hover,
        })}
      >
        <ArrowDropDown color="black" size={hover ? 0.8 : 0.7} />
      </span>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {["Open", "Picking"].includes(shipment.status) &&
        hasGetLabelPermission ? (
          <StyledMenuItem onClick={() => setDownloadLabels(true)}>
            <ListItemIcon className={classes.listIconItem}>
              <DocumentDownload color="black" />
            </ListItemIcon>
            <ListItemText primary="Get Labels" />
          </StyledMenuItem>
        ) : null}
        {["Picking", "Picked", "Packed"].includes(shipment.status) &&
        hasReadyToShipPermission ? (
          <StyledMenuItem onClick={() => setReadyToShip(true)}>
            <ListItemIcon className={classes.listIconItem}>
              <Done />
            </ListItemIcon>
            <ListItemText primary="Ready To Ship" />
          </StyledMenuItem>
        ) : null}
        {shipment.status === "Ready" && hasShipPermission ? (
          <StyledMenuItem onClick={() => setShipConfirm(true)}>
            <ListItemIcon className={classes.listIconItem}>
              <Done />
            </ListItemIcon>
            <ListItemText primary="Ship" />
          </StyledMenuItem>
        ) : null}
      </StyledMenu>
      {downloadLabels && (
        <LTLLabelsModal
          details={shipment.details}
          shipment_number={shipment.shipment_number}
          onClose={handleCloseDownloadLabel}
        />
      )}
      {readyToShip && (
        <ReadyToShip shipment={shipment} onClose={handleCloseReadyToShip} />
      )}

      <ConfirmDialog
        title={`Ship the Shipment (#${shipment.shipment_number})`}
        confirmText="Ship"
        onClose={handleCloseShipConfirm}
        onConfirm={handleLTLShip}
        open={shipConfirm}
      />
    </>
  );
}

const headers = [
  {
    id: "sku",
  },
  {
    id: "location",
    render: (r) =>
      `${r.location} ${r.pick_location ? ` - (${r.pick_location})` : ""} `,
  },
  {
    id: "qty",
    render: (r) => (
      <PickStatus picked_qty={r.picked_qty} total_qty={r.total_qty} />
    ),
  },
  {
    id: "status",
    render: (r) => <StatusLabel status={r.status} />,
  },
];

function ReadyToShip({ shipment, onClose }) {
  const layoutDispatch = useLayoutDispatch();
  const [details, setDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const shipmentDetails = shipment.details;
    const data = [];
    shipmentDetails.forEach((detail) => {
      const transactions = detail.transactions;
      let picked_qty = transactions
        .filter((t) => t.action_type === "Picked")
        .reduce((total, t) => total + t.quantity, 0);

      data.push({
        id: detail.id,
        picked_qty,
        total_qty: detail.quantity,
        sku: detail.product.sku,
        location: detail.location.code,
        pick_location: detail.pick_location ? detail.pick_location.code : null,
        status: detail.status,
      });
    });
    setDetails(_.orderBy(data, "status", "asc"));
  }, [shipment]);

  const handleSubmit = () => {
    setIsLoading(true);
    readyToShip({
      body: {
        shipment_number: shipment.shipment_number,
      },
      responseSetter: (res) => {
        const { success, message } = res;
        if (!success) {
          setIsLoading(false);
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }

        setIsLoading(false);
        popupNotification({
          dispatch: layoutDispatch,
          message,
          status: "success",
        });
        onClose(true);
      },
    });
  };

  return (
    <Dialog
      open={true}
      onClose={isLoading ? null : () => onClose(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle> Ready To Ship (#{shipment.shipment_number}) </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DefaultTable
            headers={headers}
            data={{
              items: details,
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onClose(false)}
          disabled={isLoading}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
