import _ from "lodash";

const locationCodeValidation = (location_code) => {
  const pattern = new RegExp("([Dd]?\\d{3})[-]?(\\w{3})[-]?(\\d{2})", "g");
  const groups = pattern.exec(location_code.trim());

  if (!groups || groups[0].length !== location_code.length) {
    return false;
  }

  return `${groups[1]}-${groups[2]}-${groups[3]}`;
};

const productAttributeValidation = (attribute) => {
  if (locationCodeValidation(attribute) || attribute.startsWith("1Z")) {
    return false;
  }

  if (!_.some(attribute, (x) => x !== "0")) {
    return false;
  }

  // Example Scanned Value : 3563 4567 5675 4566, 3335 2263 5448 8066
  const furniture_number_regex = /^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$/;
  if (furniture_number_regex.test(attribute)) {
    return false;
  }

  const value_to_number = Number(attribute);
  if (
    attribute.length < 12 &&
    Number.isInteger(value_to_number) &&
    attribute.length !== 5
  ) {
    return false;
  }

  let cleared_value = attribute;
  if (attribute.length > 24) {
    const split_by_comma = attribute.split(",");
    if (split_by_comma.length !== 4) {
      return false;
    }
    cleared_value = split_by_comma[0];
  }

  cleared_value = cleared_value.replaceAll("'", "").replaceAll("-", " ");
  let split_by_space = cleared_value.split(" ");
  const last_index = split_by_space.length - 1;
  split_by_space[last_index] = split_by_space[last_index].replace("X", "x");
  return split_by_space.join(" ");
};

const isRug = (value) => {
  // outsource
  if (parseInt(value) >= 10000) {
    return true;
  }
  const pattern = new RegExp("(([A-Z]{4} )?[A-Z]{3}\\d{4} )", "g");
  const groups = pattern.exec(value.trim());

  return groups;
};

const doesStartLikeLocationCode = (value) => {
  const regex = /^(D\d{3}|\d{3}|DOCK|FRN|NAT|Buggy|Order|Fork|RC|LTL|FURN).*/i;
  return regex.test(value);
};

export {
  doesStartLikeLocationCode,
  isRug,
  locationCodeValidation,
  productAttributeValidation,
};
