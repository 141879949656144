import React, { useCallback, useEffect, useState } from "react";

import { debounce } from "lodash";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Link,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useTranslation } from "react-i18next";

import { getUsers, getGroups, updateUser, createUser } from "api/user";

import {
  Button,
  Checkbox,
  Dropdown,
  InputContainer,
  TextInput,
} from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import {
  Class,
  Person,
  Plus as Add,
  Close,
  Check,
  X as Clear,
  UserCircle,
  Clipboard,
  Mail,
  LockClosed,
  OfficeBuilding,
  Briefcase,
  ListAlt,
} from "icons";

import themes from "themes";

import { initialAPIListValue } from "utils/constants";
import getClearAdornment from "utils/form";
import { passwordValidator, toTitleCase } from "utils/string";

import useStyles from "./styles";

const initialUserFormValue = {
  email: "",
  username: "",
  first_name: "",
  last_name: "",
  related_product: "",
  department: "",
  password: "",
  title: "",
  is_active: true,
  is_listable: true,
  groups: [],
};

const getInitialValidationValues = (value) => ({
  username: value,
  email: value,
  first_name: value,
  password: value,
});

export default function User(props) {
  const { permissions } = useUserState();
  const { t } = useTranslation();
  const classes = useStyles();
  const layoutDispatch = useLayoutDispatch();

  const cells = [
    {
      id: "username",
      render: (u) => {
        return (
          <Link
            color="primary"
            className="text-primary"
            onClick={() => handleDetails(u)}
            style={{ cursor: "pointer" }}
          >
            {u.user ? toTitleCase(u.user.username) : "-"}
          </Link>
        );
      },
    },
    { id: "department" },
    { id: "firstname", render: (u) => u.user.first_name },
    { id: "lastname", render: (u) => u.user.last_name },
    {
      id: "related_product_display",
      label: `${t("related_product")}`,
    },
    {
      id: "last_login",
      render: (u) => u.user.last_login,
    },
    {
      id: "active",
      render: (u) => {
        return u.user.is_active ? <Check color="#25C12A"/> : <Clear color="#F54428" />;
      },
    },
  ];

  const related_products = ["Rug", "Furniture"];
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [users, setUsers] = useState(initialAPIListValue);
  const [search, setSearch] = useState({
    user__username: "",
    user__is_active: "",
  });
  const [detailOpen, setDetailOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [groups, setGroups] = useState(initialAPIListValue);
  const [formValidity, setFormValidity] = useState(
    getInitialValidationValues(true),
  );
  const [errorVisibility, setErrorVisibility] = useState(
    getInitialValidationValues(false),
  );
  const [passwordRequired, setPasswordRequired] = useState(true);

  const [userData, setUserData] = useState(initialUserFormValue);

  const userTranslation = toTitleCase(t("user"));

  useEffect(() => {
    getGroups({
      setFunc: setGroups,
    });
  }, []);

  useEffect(() => {
    if (users.isFetching) {
      getUsers({
        params: {
          limit: rowsPerPage,
          offset: rowsPerPage * page,
          is_manager: false,
          ...search,
        },
        setError,
        setFunc: setUsers,
        paginated: true,
      });
    }
  }, [rowsPerPage, page, search, users.isFetching]);

  useEffect(() => {
    if (error) {
      popupNotification({
        dispatch: layoutDispatch,
        message: error,
      });
      setError(false);
    }
  }, [error, layoutDispatch]);

  useEffect(() => {
    const isValid = testInputLength(userData.username, 2);
    setFormItemValidity("username", isValid);
  }, [userData.username]);

  useEffect(() => {
    const isValid = testInputLength(userData.first_name, 2);
    setFormItemValidity("first_name", isValid);
  }, [userData.first_name]);

  useEffect(() => {
    if (!userData.email) {
      setFormItemValidity("email", true);
      return;
    }
    const emailValidator = new RegExp(/\S+@\S+/gm);
    const isValid = emailValidator.test(userData.email);
    setFormItemValidity("email", isValid);
  }, [userData.email]);

  useEffect(() => {
    // When updating user info, the password field is not required if it's empty
    setPasswordRequired(isCreating || userData.password !== "");
    const isValid = passwordValidator.test(userData.password);
    setFormItemValidity("password", isValid);
  }, [userData.password, isCreating]);

  const setFormItemValidity = (item, isValid) => {
    setFormValidity((prev) => ({ ...prev, [item]: isValid }));
  };

  const isFormValid = () => {
    for (let key of Object.keys(formValidity)) {
      if (key === "password" && !passwordRequired) {
        continue;
      }
      if (!formValidity[key] || (userData[key] === "" && key !== "email")) {
        return false;
      }
    }
    return true;
  };

  const testInputLength = (input, minLength) => {
    return input.length >= minLength;
  };

  const notifyErrors = (errorResult) => {
    if (Array.isArray(errorResult)) {
      errorResult.forEach((e) => {
        popupError(e);
      });
    } else {
      popupError(errorResult);
    }
  };

  const popupError = (e) => {
    popupNotification({
      dispatch: layoutDispatch,
      message: e,
      status: "error",
    });
  };

  const handleDetails = (row) => {
    let groups = [];
    row.groups.forEach((group) => groups.push(group));
    setUserData({
      email: row.user.email || "",
      username: row.user.username || "",
      first_name: row.user.first_name || "",
      last_name: row.user.last_name || "",
      wms_user_id: row.id || "",
      title: row.title || "",
      is_active: row.user.is_active,
      is_listable: row.is_listable,
      related_product: row.related_product_display || "",
      department: row.department || "",
      groups: groups || [],
      password: "",
    });
    setDetailOpen(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeFilterInput = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setUsers({ ...users, isFetching: true });
  };

  const openCreateDialog = () => {
    setIsCreating(true);
    setDetailOpen(true);
    setUserData(initialUserFormValue);
  };

  const handleNewUserInput = (prop) => (event) => {
    setUserData({
      ...userData,
      [prop]:
        prop === "username"
          ? event.target.value.toLowerCase()
          : event.target.value,
    });
  };

  const handleGroupsCheckbox = (prop) => (event) => {
    let groups = userData.groups;
    let value = event.target.value;
    let idx = groups.indexOf(value);
    if (idx !== -1) {
      userData.groups.splice(idx, 1);
    } else {
      groups.push(value);
    }
    setUserData({
      ...userData,
      groups: groups,
    });
  };

  const handleCloseDetail = () => {
    setDetailOpen(false);
    setIsCreating(false);
    setIsFetching(false);
    setErrorVisibility(getInitialValidationValues(false));
  };

  const handleUpdateUserAction = () => {
    setIsFetching(true);
    updateUser({
      body: userData,
      responseSetter: (result) => {
        const { success, message } = result;
        if (!success) {
          notifyErrors(message);
          return;
        }
        handleCloseDetail();
        setUsers({ ...users, isFetching: true });
        popupNotification({
          dispatch: layoutDispatch,
          message: `${userTranslation} ${t("updated")}`,
          status: "success",
        });
      },
    });
  };

  const handleCreateUserAction = () => {
    setIsFetching(true);
    createUser({
      body: userData,
      responseSetter: (result) => {
        const { success, message } = result;
        if (!success) {
          notifyErrors(message);
          return;
        }
        handleCloseDetail();
        setUsers({ ...users, isFetching: true });
        popupNotification({
          dispatch: layoutDispatch,
          message: `${userTranslation} ${t("created")}`,
          status: "success",
        });
      },
    });
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setUsers({ ...users, isFetching: true });
    }, 500),
    [],
  );

  return (
    <>
      <PageTitle
        title={t("users")}
        button={
          permissions?.includes("auth.add_user") && (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={openCreateDialog}
              >
                <Add color={themes.default.palette.common.white} />
                {t("create")}
              </Button>
          )
        }
      />
      <InputContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormattedTextField
              fullWidth
              id="username_search"
              label={t("username")}
              variant="outlined"
              value={search.user__username}
              onChange={handleChangeFilterInput("user__username")}
              InputProps={clearAdornment("user__username")}
              LeftIcon={Person}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dropdown
              id="active_search"
              title={t("active")}
              value={search.user__is_active}
              onChange={handleChangeFilterInput("user__is_active")}
              items={[true, false].map((status) => ({
                value: status,
                label: toTitleCase(`${status}`),
              }))}
              LeftIcon={Class}
            />
          </Grid>
          <Grid item lg={4} style={{ borderRight: "none" }} />
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Dialog
            open={detailOpen}
            scroll="paper"
            maxWidth="lg"
            onClose={handleCloseDetail}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogContent dividers={true}>
              {isFetching ? (
                <Alert severity="info">
                  {isCreating ? "Creating" : "Updating"} user, please wait..
                </Alert>
              ) : (
                <>
                  <span
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      fontSize: themes.default.typography.pxToRem(20),
                    }}
                  >
                    {isCreating ? "Create User" : "Update User"}
                  </span>
                  <div
                    style={{
                      cursor: "pointer",
                      width: themes.default.typography.pxToRem(25),
                      float: "right",
                    }}
                    onClick={() => handleCloseDetail()}
                  >
                    <Close />
                  </div>
                  <Divider
                    style={{
                      marginTop: themes.default.spacing(2),
                      marginBottom: themes.default.spacing(2),
                    }}
                  />
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextInput
                        fullWidth
                        id="username"
                        label={t("username")}
                        variant="outlined"
                        value={userData.username}
                        onChange={handleNewUserInput("username")}
                        onBlur={() => {
                          setErrorVisibility({
                            ...errorVisibility,
                            username: true,
                          });
                        }}
                        error={
                          !formValidity.username &&
                          errorVisibility.username &&
                          t("invalid_username")
                        }
                        LeftIcon={UserCircle}
                        disabled={!isCreating}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextInput
                        fullWidth
                        id="firstname"
                        label={t("firstname")}
                        variant="outlined"
                        value={userData.first_name}
                        onChange={handleNewUserInput("first_name")}
                        onBlur={() => {
                          setErrorVisibility({
                            ...errorVisibility,
                            first_name: true,
                          });
                        }}
                        error={
                          !formValidity.first_name &&
                          errorVisibility.first_name &&
                          t("invalid_firstname")
                        }
                        LeftIcon={Clipboard}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextInput
                        fullWidth
                        id="lastname"
                        label={t("lastname")}
                        variant="outlined"
                        value={userData.last_name}
                        onChange={handleNewUserInput("last_name")}
                        LeftIcon={Clipboard}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextInput
                        fullWidth
                        id="email"
                        label={t("email")}
                        variant="outlined"
                        value={userData.email}
                        onChange={handleNewUserInput("email")}
                        onBlur={() => {
                          setErrorVisibility({
                            ...errorVisibility,
                            email: true,
                          });
                        }}
                        error={
                          !formValidity.email &&
                          errorVisibility.email &&
                          t("invalid_email")
                        }
                        LeftIcon={Mail}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextInput
                        fullWidth
                        id="password"
                        label={t("password")}
                        type="password"
                        variant="outlined"
                        value={userData.password}
                        onChange={handleNewUserInput("password")}
                        onBlur={() => {
                          setErrorVisibility({
                            ...errorVisibility,
                            password: true,
                          });
                        }}
                        error={
                          !formValidity.password &&
                          errorVisibility.password &&
                          t("invalid_password")
                        }
                        LeftIcon={LockClosed}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextInput
                        fullWidth
                        id="department"
                        label={t("department")}
                        type="department"
                        variant="outlined"
                        value={userData.department}
                        onChange={handleNewUserInput("department")}
                        LeftIcon={OfficeBuilding}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextInput
                        fullWidth
                        id="title"
                        label={t("title")}
                        type="title"
                        variant="outlined"
                        value={userData.title}
                        onChange={handleNewUserInput("title")}
                        LeftIcon={Briefcase}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Dropdown
                        id="related_product"
                        title={t("related_product")}
                        value={userData.related_product}
                        onChange={handleNewUserInput("related_product")}
                        items={related_products.map((rp) => ({
                          value: rp,
                          label: rp,
                        }))}
                        LeftIcon={ListAlt}
                      />
                    </Grid>

                    <Divider style={{ width: "100%" }} />
                    <Grid item xs={12} sm={6} md={3}>
                      <div style={{ paddingLeft: themes.default.spacing(1.5) }}>
                        <FormControlLabel
                          key={"is_active"}
                          value={userData.is_active}
                          control={
                            <Checkbox
                              checked={userData.is_active}
                              onChange={() =>
                                setUserData({
                                  ...userData,
                                  is_active: !userData.is_active,
                                })
                              }
                              color="blue"
                            />
                          }
                          label={t("active")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <div style={{ paddingLeft: themes.default.spacing(1.5) }}>
                        <FormControlLabel
                          key={"is_listable"}
                          value={userData.is_listable}
                          control={
                            <Checkbox
                              checked={userData.is_listable}
                              onChange={() =>
                                setUserData({
                                  ...userData,
                                  is_listable: !userData.is_listable,
                                })
                              }
                              color="blue"
                            />
                          }
                          label={"Listable"}
                        />
                      </div>
                    </Grid>

                    <Divider style={{ width: "100%" }} />
                    <Grid item xs={12} lg={12}>
                      <div style={{ padding: themes.default.spacing(1.5) }}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <FormLabel component="legend">
                            {t("groups")}
                          </FormLabel>
                          <Grid container>
                            {groups.items &&
                              groups.items.groups &&
                              groups.items.groups.length &&
                              (userData.groups || isCreating) &&
                              groups.items.groups
                                .sort((a, b) => a.localeCompare(b))
                                .map((group) => (
                                  <Grid item xs={12} lg={4} key={group}>
                                    <FormGroup aria-label="position">
                                      <FormControlLabel
                                        value={group}
                                        control={
                                          <Checkbox
                                            checked={
                                              userData.groups.indexOf(group) !==
                                              -1
                                            }
                                            onChange={handleGroupsCheckbox(
                                              "groups",
                                              group,
                                            )}
                                            color="blue"
                                          />
                                        }
                                        label={group}
                                      />
                                    </FormGroup>
                                  </Grid>
                                ))}
                          </Grid>
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.divider}>
                    <Divider />
                  </Grid>
                  <Grid container justifyContent="flex-end">
                    <div
                      className={["pull-right pr-5", classes.button].join(" ")}
                    >
                      <Button
                        variant="contained"
                        color="blue"
                        disabled={isFetching}
                        onClick={() => {
                          if (!isFormValid()) {
                            setErrorVisibility(
                              getInitialValidationValues(true),
                            );
                            return;
                          }
                          isCreating
                            ? handleCreateUserAction()
                            : handleUpdateUserAction();
                        }}
                      >
                        <span className="btn-wrapper--label">
                          {t(isCreating ? "create" : "update")}
                        </span>
                      </Button>
                    </div>
                  </Grid>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <div className={classes.button}>
                <Button
                  onClick={() => handleCloseDetail()}
                  color="primary"
                  variant="outlined"
                  disabled={isFetching}
                >
                  {t("close")}
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            headers={cells}
            data={users}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            filters={{
              ...search,
              user__is_active: null,
              user_active:
                search.user__is_active === ""
                  ? null
                  : search.user__is_active
                  ? "True"
                  : "False",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
