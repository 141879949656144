import React, { useEffect, createElement } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error/Error";
import Login from "../pages/login/Login";
import PasswordReset from "../pages/password/PasswordReset";
import PasswordConfirm from "../pages/password/PasswordConfirm";

// context
import { useUserState, useUserDispatch, validToken } from "context/UserContext";

import { getWithExpiry } from "../utils/storage";
import parseJwt from "utils/jwt";

export default function App() {
  // global
  const { isAuthenticated } = useUserState();
  const userDispatch = useUserDispatch();
  const access = getWithExpiry("access");
  const { seller_id } = parseJwt(access);
  const path = seller_id === 1 ? "/app/my-work" : "/app/shipments";

  useEffect(() => {
    if (access) {
      validToken(userDispatch, access);
    }
  }, [access, userDispatch]);

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={path} />} />
        <Route exact path="/app" render={() => <Redirect to={path} />} />
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/password_reset" component={PasswordReset} />
        <PublicRoute path="/password_confirm" component={PasswordConfirm} />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    const { seller_id } = parseJwt(getWithExpiry("access"));

    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: seller_id === 1 ? "/app/my-work" : "/app/shipments",
              }}
            />
          ) : (
            createElement(component, props)
          )
        }
      />
    );
  }
}
