import React, { useRef, useState } from "react";

import _ from "lodash";
import {
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { getInventories } from "api/inventory";
import { getProductsWithAttributes } from "api/products";

import {
  ConfirmDialog,
  MarkLocationAsInspected,
  MobileButtonContainer,
  MobileResetButton,
  Spacer,
} from "components/Custom";
import { TextInputWithKeyboard } from "components/FormElements";
import PageTitle from "components/PageTitle";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { QrCode2, SpaceBar, TextSnippet } from "icons";

import { LocationTypes } from "utils/constants";
import { clearInput } from "utils/dom";
import { formatProductSKU, separateQtyFromValue } from "utils/string";
import { locationCodeValidation } from "utils/validations";
import { getLocations } from "api/locations";
import { createTransfer } from "api/transfer";

const initialFormData = {
  from_location_id: "",
  from_location_code: "",
  from_location_type: "",
  product_sku: "",
  to_location_code: "",
  to_location_id: "",
  quantity: 1,
  inventory: [],
  product: {},
};

const initialForceModal = {
  open: false,
  title: "",
  message: "",
};

export default function Transfer() {
  const [t] = useTranslation();
  const layoutDispatch = useLayoutDispatch();

  const [formData, setFormData] = useState(initialFormData);
  const [locationList, setLocationList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [inspectedLocations, setInspectedLocations] = useState([]);
  const [fromInspectionDialogOpen, setFromInspectionDialogOpen] = useState(
    false,
  );
  const [toInspectionDialogOpen, setToInspectionDialogOpen] = useState(false);
  const [forceModal, setForceModal] = useState(initialForceModal);
  const [itemNotAvailable, setItemNotAvailable] = useState(false);

  const fromLocationEl = useRef(null);
  const toLocationEl = useRef(null);
  const productEl = useRef(null);
  const qtyEl = useRef(null);

  const handleFromLocationSubmit = (value) => {
    let location_code = locationCodeValidation(value.trim());
    if (!location_code) {
      location_code = value.trim();
    }

    getInventories({
      params: {
        location__code: location_code,
        limit: 10000,
      },
      responseSetter: (data) => {
        if (!data.results.length) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: "This location is empty.",
            status: "error",
          });
        }
        const exactMatches = data.results.filter(
          (i) => i.location.code.toLowerCase() === location_code.toLowerCase(),
        );
        if (!exactMatches.length) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: "This location is empty.",
            status: "error",
          });
        }
        const from_location = exactMatches[0].location;
        setFormData((prev) => ({
          ...prev,
          from_location_id: from_location.id,
          inventory: exactMatches,
          from_location_code: from_location.code,
          from_location_type: from_location.location_type_display,
        }));
        window.scroll({ top: 100, left: 0, behavior: "smooth" });
        if (productEl && productEl.current) {
          productEl.current.focus();
        }
      },
    });
  };

  const handleProductSubmit = (sku) => {
    let { value, qty } = separateQtyFromValue(sku);
    value = formatProductSKU(value);

    getProductsWithAttributes({
      params: { value_iexact: value },
      setError: (e) =>
        popupNotification({
          dispatch: layoutDispatch,
          message: e.error,
        }),
      responseSetter: (data) => {
        if (!data.results.length) {
          setFromInspectionDialogOpen(true);
          setItemNotAvailable(true);
          clearInput(productEl);
          return;
        }

        const productSku = data.results[0].product_sku;
        const inventory = formData.inventory.find(
          (i) => i.product.sku === productSku,
        );

        if (!inventory) {
          setFromInspectionDialogOpen(true);
          setItemNotAvailable(true);
          clearInput(productEl);
          return;
        }

        setFormData((prev) => ({
          ...prev,
          product_sku: value,
          product: inventory.product,
          quantity: qty || 1,
        }));

        window.scroll({ top: 100, left: 0, behavior: "smooth" });
        if (toLocationEl && toLocationEl.current) {
          toLocationEl.current.focus();
        }
      },
    });
  };

  const handleToLocationSubmit = (value) => {
    setLocationList([]);
    let location_code = locationCodeValidation(value.trim());
    if (!location_code) {
      location_code = value.trim();
    }
    getLocations({
      params: {
        code: location_code,
        limit: 1,
      },
      paginated: true,
      responseSetter: (data) => {
        if (!data.results || !data.results.length) {
          clearInput(toLocationEl);
          return popupNotification({
            dispatch: layoutDispatch,
            message: "Location not found.",
          });
        }
        const toLocation = data.results[0];
        setFormData((prev) => ({
          ...prev,
          to_location_code: toLocation.code,
          to_location_id: toLocation.id,
        }));
        window.scroll({ top: 100, left: 0, behavior: "smooth" });
        if (qtyEl && qtyEl.current) {
          qtyEl.current.focus();
        }
      },
    });
  };

  const handleQuantitySubmit = ({ target: { value } }) => {
    const isValid = /^\d+$/.test(value);
    if (!isValid) {
      popupNotification({
        dispatch: layoutDispatch,
        message: "Invalid quantity.",
      });
    }
    setFormData((prev) => ({
      ...prev,
      quantity: /^\d+$/.test(value) ? value : "",
    }));
  };

  const handleFormSubmit = (e, force) => {
    if (e) {
      e.preventDefault();
    }
    const quantity = parseInt(formData.quantity);
    if (quantity < 0 || Number.isNaN(quantity) || quantity > 999) {
      setFormData({ ...formData, quantity: "" });
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Invalid quantity",
      });
    }
    setIsDisabled(true);
    createTransfer({
      body: {
        product_id: formData.product.id,
        from_location_id: formData.from_location_id,
        to_location_id: formData.to_location_id,
        qty: formData.quantity,
        force: force || false,
      },
      responseSetter: (data) => {
        setIsDisabled(false);
        if (data.success) {
          setFormData({
            ...formData,
            product_sku: "",
            to_location_code: "",
            to_location_id: "",
          });
          return popupNotification({
            dispatch: layoutDispatch,
            message: data.message,
            status: "success",
          });
        } else {
          if (data.key === "product_mismatch") {
            return setForceModal({
              open: true,
              title: "Product Mismatch❗",
              message: data.message,
            });
          } else if (data.key === "qty_mismatch") {
            return setForceModal({
              open: true,
              title: "Quantity Mismatch❗",
              message: data.message,
            });
          } else if (data.key === "empty_location") {
            return setForceModal({
              open: true,
              title: "Empty Location❗",
              message: data.message,
            });
          }
          return popupNotification({
            dispatch: layoutDispatch,
            message: data.message,
          });
        }
      },
    });
  };

  const afterInspectConfirm = (direction = "from") => {
    setInspectedLocations((prev) => [
      ...prev,
      direction === "from"
        ? formData.from_location_id
        : formData.to_location_id,
    ]);
    if (direction === "from") {
      setFormData(initialFormData);
    } else {
      setFormData((prev) => ({
        ...prev,
        to_location_code: "",
        to_location_id: "",
      }));
    }

    setFromInspectionDialogOpen(false);
    setToInspectionDialogOpen(false);
  };

  const searchAlternateLocations = () => {
    const locationType = LocationTypes.find(
      (location) => location.label === formData.product.product_type_display,
    );
    getInventories({
      params: {
        product_id: formData.product.id,
        location__location_type: locationType
          ? locationType.value
          : LocationTypes[0].value,
      },
      responseSetter: (data) => {
        if (!data.results.length) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: t("no_results_found"),
            status: "error",
          });
        } else {
          const locations = data.results
            .map((item) => ({
              ...item.location,
            }))
            .sort((a, b) => (a.code > b.code ? 1 : a.code < b.code ? -1 : 0));
          setLocationList(_.uniqBy(locations, "code"));
        }
      },
    });
  };

  const searchEmptyLocations = () => {
    const locationType = LocationTypes.find(
      (location) => location.label === formData.product.product_type_display,
    );
    getLocations({
      params: {
        empty: true,
        location_type: locationType
          ? locationType.value
          : LocationTypes[0].value,
        product_sku: formData.product_sku,
      },
      responseSetter: (data) => {
        if (!data.results.length) {
          return popupNotification({
            dispatch: layoutDispatch,
            message: t("no_results_found"),
            status: "error",
          });
        } else {
          const locations = data.results.sort((a, b) =>
            a.code > b.code ? 1 : a.code < b.code ? -1 : 0,
          );
          setLocationList(_.uniqBy(locations, "code"));
        }
      },
    });
  };

  const clearState = () => {
    setFormData(initialFormData);
    setLocationList([]);
    setFromInspectionDialogOpen(false);
    setToInspectionDialogOpen(false);
    setForceModal(initialForceModal);
  };

  return (
    <>
      <PageTitle title="Transfer" helpText="Regular" />
      <Spacer height="2rem" />
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {!formData.from_location_code ? (
            <TextInputWithKeyboard
              fullWidth
              autoFocus
              inputRef={fromLocationEl}
              id="from_location_code"
              variant="outlined"
              label="From Location"
              defaultValue=""
              onKeyPress={({ target, key }) => {
                if (key === "Enter") handleFromLocationSubmit(target.value);
              }}
              LeftIcon={SpaceBar}
              hasBorder
            />
          ) : (
            <MarkLocationAsInspected
              title="From:"
              location_code={formData.from_location_code}
              location_id={formData.from_location_id}
              afterConfirm={() => afterInspectConfirm("from")}
              onClose={() => {
                setFromInspectionDialogOpen(false);
                setItemNotAvailable(false);
              }}
              dialogTitle="Inventory Mismatch"
              isOpen={fromInspectionDialogOpen}
              itemNotAvailable={itemNotAvailable}
            />
          )}
        </Grid>
        {formData.from_location_id ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {!formData.product_sku ? (
              <TextInputWithKeyboard
                fullWidth
                autoFocus
                inputRef={productEl}
                id="product_sku"
                variant="outlined"
                label={t("product")}
                defaultValue={formData.product_sku}
                onKeyPress={({ target, key }) => {
                  if (key === "Enter") handleProductSubmit(target.value);
                }}
                LeftIcon={QrCode2}
                showKeyboard={false}
                hasBorder
              />
            ) : (
              <Typography variant="subtitle1">
                {"SKU: "}
                <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  {formData.product_sku}
                </span>
              </Typography>
            )}
          </Grid>
        ) : null}
        {formData.product_sku ? (
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {!formData.to_location_code ? (
              <TextInputWithKeyboard
                fullWidth
                autoFocus
                inputRef={toLocationEl}
                id="to_location_code"
                variant="outlined"
                label="To Location"
                defaultValue=""
                onKeyPress={({ target, key }) => {
                  if (key === "Enter") handleToLocationSubmit(target.value);
                }}
                LeftIcon={SpaceBar}
                hasBorder
              />
            ) : (
              <MarkLocationAsInspected
                title="To:"
                location_code={formData.to_location_code}
                location_id={formData.to_location_id}
                product_sku={formData.product_sku}
                afterConfirm={() => afterInspectConfirm("to")}
                onClose={() => setToInspectionDialogOpen(false)}
                dialogTitle="Inventory Mismatch"
                isOpen={toInspectionDialogOpen}
              />
            )}
          </Grid>
        ) : null}
        {formData.to_location_code ? (
          <>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextInputWithKeyboard
                fullWidth
                autoFocus
                type="number"
                size="medium"
                name="quantity"
                inputMode="numeric"
                inputRef={qtyEl}
                label={t("quantity")}
                variant="outlined"
                error={
                  Number.isNaN(parseInt(formData.quantity)) ||
                  parseInt(formData.quantity) < 0 ||
                  parseInt(formData.quantity) > 999
                }
                value={formData.quantity}
                onChange={handleQuantitySubmit}
                LeftIcon={TextSnippet}
                hasBorder
                disabled
                showKeyboard={false}
              />
              <Spacer height="2rem" />
              <MobileButtonContainer>
                <Button
                  fullWidth
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={handleFormSubmit}
                >
                  {t("submit")}
                </Button>
              </MobileButtonContainer>
            </Grid>
          </>
        ) : null}
        {formData.product_sku && !formData.to_location_code ? (
          <>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={isDisabled}
                onClick={searchEmptyLocations}
              >
                {isDisabled ? <CircularProgress size={20} /> : "Empty Loc."}
              </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                disabled={isDisabled}
                onClick={searchAlternateLocations}
              >
                {isDisabled ? <CircularProgress size={20} /> : "Alt. Loc."}
              </Button>
            </Grid>
          </>
        ) : null}
        {locationList.length > 1 ? (
          <List dense disablePadding>
            {locationList
              .filter(
                (l) =>
                  !inspectedLocations.includes(l.id) &&
                  l.id !== formData.from_location_id,
              )
              .slice(0, 3)
              .map((location) => (
                <ListItem key={location.id}>
                  <ListItemText primary={location.code} />
                </ListItem>
              ))}
          </List>
        ) : null}
        <ConfirmDialog
          headerColor="red"
          title={forceModal.title}
          open={forceModal.open}
          confirmText="Approve Transfer"
          onClose={() => setForceModal(initialForceModal)}
          onConfirm={(e) => {
            setForceModal(initialForceModal);
            handleFormSubmit(e, true);
          }}
        >
          {Array.isArray(forceModal.message) ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {forceModal.message.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          ) : (
            forceModal.message
          )}
        </ConfirmDialog>
      </Grid>
      <Spacer height="100px" />
      <MobileResetButton onClick={clearState} />
    </>
  );
}
