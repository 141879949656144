import React, { useCallback, useEffect, useState } from "react";

import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { Badge, Button, Grid, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useTheme } from "@material-ui/styles";

import { getCollectionNames } from "api/products";
import { getContainerRecords } from "api/purchase";
import { InputContainer, TextInput } from "components/Form";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import CreateModal from "./CreateModal";
import ContainerDrawer from "./ContainerDrawer";
import ContainerReceiptModal from "./ContainerReceiptModal";

import { AssistantPhoto, Plus, Receipt, SnippetFolder } from "icons";

import { initialAPIListValue } from "utils/constants";
import { convertMergedFiltersToQueryString } from "utils/filter";
import getClearAdornment from "utils/form";

import {
  CUSTOMERS,
  DESTINATIONS,
  IMPORTERS,
  LINES,
  PO_TYPES,
  SHIP_TO_PORTS,
  VENDORS_MANUFACTURERS,
} from "./constants";

import useStyles from "./styles";
import { exportToExcel } from "utils/exportToExcel";
import { convertDataToTableView } from "utils/convert";

const exportHeaders = [
  { id: "container_number" },
  { id: "po_type", label: "PO Type" },
  { id: "po_number", label: "PO Number" },
  { id: "invoice_number" },
  { id: "manufacturer" },
  { id: "vendor" },
  { id: "customer" },
  { id: "importer" },
  {
    id: "collections",
    render: (r) => (r.collections ? JSON.parse(r.collections).join(", ") : ""),
  },
  { id: "scheduled_date" },
  { id: "etd", label: "ETD" },
  { id: "eta", label: "ETA" },
  { id: "ata", label: "ATA" },
  { id: "bol_number", label: "BOL Number" },
  { id: "line" },
  { id: "vessel" },
  {
    id: "port_and_forwarder",
    label: "Port / Forwarder",
  },
  { id: "ship_to_port", label: "Port Arrival" },
  { id: "final_destination", label: "Destination" },
  { id: "hc40_freight_cost", label: "40HC Freight Cost" },
  { id: "inland_freight_cost", label: "Inland Freight Cost" },
  { id: "loading_method" },
  { id: "notes" },
];

export default function Containers() {
  const [t] = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const headerFilters = [
    {
      key: "vendor",
      title: "Vendor",
      options: VENDORS_MANUFACTURERS,
    },
    {
      key: "customer",
      title: "Customer",
      options: CUSTOMERS,
    },
    {
      key: "importer",
      title: "Importer",
      options: IMPORTERS,
    },
    {
      key: "po_type",
      title: "PO Type",
      options: PO_TYPES.map((t) => t.value),
    },
    {
      key: "line",
      title: "Line",
      options: LINES,
    },
    {
      key: "ship_to_port",
      title: "Ship to Port",
      options: SHIP_TO_PORTS,
    },
    {
      key: "final_destination",
      title: "Final Destination",
      options: DESTINATIONS,
    },
  ];

  const initialCells = [
    {
      id: "customer",
      label: "Customer",
      render: (r) => {
        const scheduled_date = new Date(r.scheduled_date).setHours(12, 0, 0, 0);
        const today = new Date().setHours(12, 0, 0, 0);
        return (
          <Grid container alignItems="center" spacing={1}>
            <div
              className={classes.containerStatusLine}
              style={{
                backgroundColor: r.ata
                  ? "#00CC00"
                  : scheduled_date >= today
                  ? "orange"
                  : "initial",
              }}
            />
            <Grid item className={classes.customerContent}>
              <span title="Customer">{r.customer}</span>
              <Typography variant="subtitle2" className={classes.subtitle}>
                <span title="Importer">{r.importer}</span>
              </Typography>
            </Grid>
            {r.receipt_uploaded && (
              <Grid item className={classes.mlAuto}>
                <span title="Receipt Uploaded">
                  <Receipt color="#bbbb0050" />
                </span>
              </Grid>
            )}
          </Grid>
        );
      },
      sortable: true,
    },
    {
      id: "po_number",
      label: "PO",
      render: (r) => (
        <>
          <span title={r.po_number}>
            {r.po_number.slice(0, 25)}
            {r.po_number.length > 25 ? "..." : ""}
          </span>
          <Typography variant="subtitle2" className={classes.subtitle}>
            <span title="Vendor">{r.vendor}</span> /{" "}
            <span title="Manufacturer">{r.manufacturer}</span>
          </Typography>
        </>
      ),
      sortable: true,
    },
    {
      id: "container_number",
      label: "Container",
      render: (r) => (
        <Grid container>
          <Grid item>
            <span>{r.container_number}</span>
            <Typography variant="subtitle2" className={classes.subtitle}>
              <span title="Vessel">{r.vessel || "-"}</span>
            </Typography>
          </Grid>
          <Grid item className={classes.mlAuto}>
            <Badge
              color="error"
              badgeContent={
                r.po_type === "Ecommerce"
                  ? "ECOM"
                  : r.po_type === "National"
                  ? "NAT"
                  : r.po_type === "Furniture"
                  ? "FURN"
                  : "MIX"
              }
              className={
                r.po_type === "Ecommerce"
                  ? classes.ecomBadge
                  : r.po_type === "National"
                  ? classes.natBadge
                  : r.po_type === "Furniture"
                  ? classes.furnBadge
                  : classes.mixBadge
              }
            />
          </Grid>
        </Grid>
      ),
      sortable: true,
    },
    {
      id: "bol_number",
      label: "BOL",
      render: (r) => (
        <>
          <span>{r.bol_number}</span>
          <Typography variant="subtitle2" className={classes.subtitle}>
            <span title="Ship to port">{r.ship_to_port}</span> {"/"}{" "}
            <span title="Final destination">{r.final_destination}</span>
          </Typography>
        </>
      ),
      sortable: true,
    },
    {
      id: "eta",
      label: "ETA / ATA",
      render: (r) => (
        <>
          <span title="ETA">{r.eta || "-"}</span>
          <Typography variant="subtitle2" className={classes.subtitle}>
            <span title="ATA">{r.ata || "-"}</span>
          </Typography>
        </>
      ),
      sortable: true,
    },
  ];

  const [cells] = useState(initialCells);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [search, setSearch] = useState({
    po_number: "",
    container_number: "",
    invoice_number: "",
    bol_number: "",
    manufacturer: "",
    vendor: "",
    collection: "",
    line: "",
    vessel: "",
  });
  const [autocompleteValues, setAutocompleteValues] = useState({
    manufacturer: null,
    collection: null,
    line: null,
  });
  const [selectedFilters, setSelectedFilters] = useState({
    vendor: [],
    po_type: [],
    customer: [],
    importer: [],
    ship_to_port: [],
    final_destination: [],
    line: [],
  });
  const [containers, setContainers] = useState(initialAPIListValue);
  const [headerCounts, setHeaderCounts] = useState({});
  const [createModal, setCreateModal] = useState(false);
  const [receiptModal, setReceiptModal] = useState(false);
  const [container, setContainer] = useState({});
  const [editContainer, setEditContainer] = useState(null);
  const [collectionNames, setCollectionNames] = useState([]);
  const [exportDisabled, setExportDisabled] = useState(false);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("eta");

  useEffect(() => {
    getCollectionNames({
      responseSetter: (res) => {
        if (res.success) {
          setCollectionNames([...res.data, "OTHER"]);
        }
      },
    });
  }, []);

  useEffect(() => {
    if (containers.isFetching) {
      let params = {
        ...search,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      };
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getContainerRecords({
        params,
        mergedFilters: convertMergedFiltersToQueryString(selectedFilters),
        setFunc: (res) => {
          setContainers(res);
          setHeaderCounts(res.customCounts);
        },
        paginated: true,
      });
    }
  }, [
    search,
    page,
    rowsPerPage,
    selectedFilters,
    containers.isFetching,
    orderDirection,
    orderBy,
  ]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
    setContainers((prev) => ({ ...prev, isFetching: true }));
  };

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    setRowsPerPage(parseInt(value, 10));
    debouncedSearchHandler();
  };

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setContainers((prev) => ({ ...prev, isFetching: true }));
  };

  const handleChangeFilterInput = (prop) => ({ target: { value } }) => {
    setSearch((prev) => ({ ...prev, [prop]: value }));
    debouncedSearchHandler();
  };

  const handleChangeAutocompleteParams = (prop) => (_, value) => {
    setSearch((prev) => ({ ...prev, [prop]: value }));
  };

  const handleChangeAutocompleteValues = (prop) => (_, value) => {
    setAutocompleteValues((prev) => ({ ...prev, [prop]: value }));
    debouncedSearchHandler();
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setContainers({ ...containers, isFetching: true });
    }, 500),
    [],
  );

  const handleDrawerClose = (status) => {
    if (status) {
      setContainers((prev) => ({ ...prev, isFetching: true }));
    }
    setContainer({});
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `Containers-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, exportHeaders),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    getContainerRecords({
      params: {
        limit: containers.count,
        ...search,
      },
      mergedFilters: convertMergedFiltersToQueryString(selectedFilters),
      setFunc: ({ items: { results } }) => exportData(results),
    });
  };

  return (
    <>
      <PageTitle
        title="Containers"
        button={
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={() => setCreateModal(true)}
          >
            <Plus color="#fff" size={0.8} /> Create
          </Button>
        }
      />
      <InputContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <TextInput
              id="container_number"
              label={t("container_number")}
              variant="outlined"
              fullWidth
              value={search.container_number}
              onChange={handleChangeFilterInput("container_number")}
              InputProps={clearAdornment("container_number")}
              LeftIcon={Receipt}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <TextInput
              id="po_number"
              label={t("po_number")}
              variant="outlined"
              fullWidth
              value={search.po_number}
              onChange={handleChangeFilterInput("po_number")}
              InputProps={clearAdornment("po_number")}
              LeftIcon={Receipt}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <TextInput
              id="bol_number"
              label={t("bol_number")}
              variant="outlined"
              fullWidth
              value={search.bol_number}
              onChange={handleChangeFilterInput("bol_number")}
              InputProps={clearAdornment("bol_number")}
              LeftIcon={Receipt}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <TextInput
              id="vessel"
              label="Vessel"
              variant="outlined"
              fullWidth
              value={search.vessel}
              onChange={handleChangeFilterInput("vessel")}
              InputProps={clearAdornment("vessel")}
              LeftIcon={AssistantPhoto}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Autocomplete
              value={autocompleteValues.collection}
              onChange={handleChangeAutocompleteValues("collection")}
              inputValue={search.collection}
              onInputChange={handleChangeAutocompleteParams("collection")}
              id="collection"
              options={collectionNames}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label="Collection"
                  variant="outlined"
                  fullWidth
                  LeftIcon={SnippetFolder}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Autocomplete
              value={autocompleteValues.manufacturer}
              onChange={handleChangeAutocompleteValues("manufacturer")}
              inputValue={search.manufacturer}
              onInputChange={handleChangeAutocompleteParams("manufacturer")}
              id="manufacturer"
              options={VENDORS_MANUFACTURERS}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label="Manufacturer"
                  variant="outlined"
                  fullWidth
                  LeftIcon={SnippetFolder}
                />
              )}
            />
          </Grid>
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            data={containers}
            headers={cells}
            headerCounts={headerCounts}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            filters={search}
            headerFilters={headerFilters}
            onFilter={() => {
              setContainers({ ...containers, isFetching: true });
            }}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            rowOnClick={(r) => setContainer(r)}
            highlightColor={theme.palette.primary.main}
            className={classes.table}
            highlightStyle={(_r) => ({
              backgroundColor: "white",
              borderBottom: "1px solid #dddddd90",
              height: "4rem",
            })}
            exportDisabled={exportDisabled}
            handleExportData={handleExportData}
            sortActions={{ onSort, orderBy, orderDirection }}
          />
          {Object.keys(container).length ? (
            <ContainerDrawer
              onClose={handleDrawerClose}
              open={true}
              container={container}
              onEdit={(container) => {
                setEditContainer(container);
                handleDrawerClose();
              }}
              onFileDelete={() => {
                setContainers({ ...containers, isFetching: true });
                handleDrawerClose();
              }}
              onContainerReceipt={(container) => {
                setContainer(container);
                setReceiptModal(true);
              }}
            />
          ) : null}
        </Grid>
      </Grid>
      <CreateModal
        open={createModal || editContainer ? true : false}
        editContainer={editContainer}
        onClose={() => {
          setCreateModal(false);
          setEditContainer(null);
        }}
        onEdit={() => {
          setEditContainer(null);
          setContainers({ ...containers, isFetching: true });
        }}
        onCreate={() => setContainers({ ...containers, isFetching: true })}
        t={t}
      />
      <ContainerReceiptModal
        open={receiptModal}
        container={container}
        onClose={() => {
          setContainer({});
          setReceiptModal(false);
        }}
      />
    </>
  );
}
