import React, { useEffect, useState } from "react";

import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";

import { getShipments } from "api/shipment";

import PageTitle from "components/PageTitle";

import { useUserState } from "context/UserContext";

import { initialAPIListValue } from "utils/constants";

import {
  ActionsButton,
  RequestCancellationDialog,
  RevertShipmentDialog,
  ShipmentCancelModal,
  ShipTheShipmentDialog,
} from "./ShipmentActions";
import ShipmentPreview from "./ShipmentPreview";

export default function ShipmentDetail() {
  const { shipment_id } = useParams();

  const history = useHistory();
  const { permissions } = useUserState();

  const [shipment, setShipment] = useState(initialAPIListValue);
  const [cancelShipmentID, setCancelShipmentID] = useState(false);
  const [cancelRequestedShipmentID, setCancelRequestedShipmentID] = useState(
    false,
  );
  const [cancellationOption, setCancellationOption] = useState("");
  const [revertShipmentID, setRevertShipmentID] = useState(false);
  const [hasShippedItem, setHasShippedItem] = useState(false);
  const hasCancelPermission = permissions?.includes(
    "contenttypes.shipment.cancel",
  );
  const [shipShipmentNumber, setShipShipmentNumber] = useState("");

  useEffect(() => {
    getShipments({
      id: shipment_id,
      responseSetter: (data) => setShipment({ isFetching: false, ...data }),
    });
  }, [shipment_id]);

  return shipment.isFetching ? (
    <CircularProgress />
  ) : (
    <>
      <ShipmentCancelModal
        shipment_id={cancelShipmentID}
        close={() => setCancelShipmentID(false)}
        afterCancelled={() => history.push("/app/shipments")}
      />
      <RequestCancellationDialog
        shipment_id={cancelRequestedShipmentID}
        close={() => setCancelRequestedShipmentID(false)}
        afterCancelled={() => history.push("/app/shipments")}
        option={cancellationOption}
      />
      <ShipTheShipmentDialog
        shipment_number={shipShipmentNumber}
        close={(status = false) => {
          if (status) {
            window.location.reload();
          }
          setShipShipmentNumber("");
        }}
      />
      <RevertShipmentDialog
        shipment_id={revertShipmentID}
        close={(status = false) => {
          if (status) {
            window.location.reload();
          }
          setRevertShipmentID("");
        }}
      />
      <PageTitle
        title={shipment.shipment_number}
        backLink="/app/shipments"
        button={
          hasCancelPermission && (
            <ActionsButton
              style={{ marginRight: "10px" }}
              status={shipment.status}
              onCancelRequestClick={(option) => {
                setCancelRequestedShipmentID(shipment_id);
                setCancellationOption(option);
              }}
              onCancelClick={() => setCancelShipmentID(shipment_id)}
              onShipClick={() =>
                setShipShipmentNumber(shipment.shipment_number)
              }
              onRevertClick={() => setRevertShipmentID(shipment_id)}
              hasShippedItem={hasShippedItem}
            />
          )
        }
        useHistoryBack
      />
      <ShipmentPreview
        shipment={shipment}
        setHasShippedItem={setHasShippedItem}
      />
    </>
  );
}
