import React, { useCallback, useEffect, useState } from "react";

import { debounce } from "lodash";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { getMovements } from "api/movement";

import {
  Checkbox,
  DatePickerGroup,
  Dropdown,
  InputContainer,
  TextInput,
} from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import {
  ChevronDoubleDown,
  ChevronDoubleUp,
  ListAlt,
  Person,
  QrCode2,
  SpaceBar,
} from "icons";

import { initialAPIListValue } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import { toTitleCase } from "utils/string";

export default function MovementHistory() {
  const { t } = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const [movements, setMovements] = useState(initialAPIListValue);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [search, setSearch] = useState({
    operation_type: "",
    product__sku: "",
    location__code: "",
    created_by__username: "",
    end_date: "",
    start_date: "",
  });
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [hideBuggies, setHideBuggies] = useState(true);
  const [exportDisabled, setExportDisabled] = useState(false);
  const [headerCounts, setHeaderCounts] = useState({});

  const { permissions } = useUserState();

  const cells = [
    {
      id: "product__sku",
      label: "product_sku",
      exportLabel: "Inventory Id",
      render: (r) => r.product.sku,
      sortable: true,
    },
    { id: "location", render: (r) => r.location.code },
    { id: "qty", exportLabel: "Quantity", sortable: true },
    {
      id: "operation_type",
      render: (r) =>
        r.operation_type === 1 ? (
          <Grid container alignItems="center">
            <ChevronDoubleUp color="#25C12A" />
            <span style={{ marginLeft: 5 }}>Pick</span>
          </Grid>
        ) : (
          <Grid container alignItems="center">
            <ChevronDoubleDown color="#F54428" />
            <span style={{ marginLeft: 5 }}>Shelf</span>
          </Grid>
        ),
    },
    { id: "created_by", render: (r) => toTitleCase(r.created_by.username) },
    {
      id: "created_at",
      render: (r) =>
        new Date(r.created_at).toLocaleString("en-US", {
          timeZone: "America/New_York",
        }),
      sortable: true,
    },
  ];

  useEffect(() => {
    if (movements.isFetching) {
      let params = {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        ...search,
        hide_buggies: hideBuggies ? hideBuggies : null,
      };
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getMovements({
        params,
        setFunc: (res) => {
          setMovements(res);
          setHeaderCounts(res.customCounts);
        },
        paginated: true,
      });
    }
  }, [
    search,
    page,
    movements.isFetching,
    rowsPerPage,
    hideBuggies,
    orderDirection,
    orderBy,
  ]);

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setMovements({ ...movements, isFetching: true });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setMovements({ ...movements, isFetching: true });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setMovements({ ...movements, isFetching: true });
  };

  const handleChangeFilterInput = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const handleCheckBox = () => {
    setHideBuggies((prev) => (prev ? false : true));
    setMovements({ ...movements, isFetching: true });
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `Movement-History-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, cells),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    if (movements.count > 1000) {
      setExportDisabled(false);
      return popupNotification({
        dispatch: layoutDispatch,
        message: "The count of records to export must be less than 1000.",
        status: "warning",
      });
    }

    if (movements.items.length === movements.count) {
      exportData(movements.items);
    } else {
      getMovements({
        params: {
          limit: movements.count,
          ...search,
          hide_buggies: hideBuggies ? hideBuggies : null,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setMovements((prev) => ({ ...prev, isFetching: true }));
    }, 500),
    [],
  );

  return (
    <>
      <PageTitle title={t("movement_history")} />
      <InputContainer>
        <Grid container>
          <DatePickerGroup
            startDateValue={search.start_date}
            startDateOnChange={handleChangeFilterInput("start_date")}
            endDateValue={search.end_date}
            endDateOnChange={handleChangeFilterInput("end_date")}
            itemGridProps={{ xs: 12, sm: 6, md: 4, lg: 3 }}
          />
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormattedTextField
              fullWidth
              id="sku"
              label={t("product_sku")}
              variant="outlined"
              value={search.product__sku}
              onChange={handleChangeFilterInput("product__sku")}
              isProductSKU={true}
              InputProps={clearAdornment("product__sku")}
              LeftIcon={QrCode2}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormattedTextField
              fullWidth
              id="location"
              label={t("location")}
              variant="outlined"
              value={search.location__code}
              onChange={handleChangeFilterInput("location__code")}
              isLocationCode={true}
              InputProps={clearAdornment("location__code")}
              LeftIcon={SpaceBar}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextInput
              fullWidth
              id="created_by"
              label={t("created_by")}
              variant="outlined"
              value={search.created_by__username}
              onChange={handleChangeFilterInput("created_by__username")}
              InputProps={clearAdornment("created_by__username")}
              LeftIcon={Person}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dropdown
              id={"operation_type"}
              title={t("operation_type")}
              value={search.operation_type}
              onChange={handleChangeFilterInput("operation_type")}
              items={[
                { value: 1, label: "Pick" },
                { value: 2, label: "Shelf" },
              ]}
              LeftIcon={ListAlt}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Checkbox
              checked={hideBuggies}
              onChange={handleCheckBox}
              color="blue"
              label={"Hide Buggies"}
            />
          </Grid>
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            headers={cells}
            headerCounts={headerCounts}
            data={movements}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleExportData={handleExportData}
            disableExportData={
              !permissions?.includes("warehouse.export_data.movement")
            }
            sortActions={{ onSort, orderBy, orderDirection }}
            exportDisabled={exportDisabled}
            setExportDisabled={setExportDisabled}
            filters={{
              ...search,
              hide_buggies: hideBuggies ? "True" : "False",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
