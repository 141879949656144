import React, { useEffect, useCallback, useRef, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

import { createLocationSKUValidation } from "api/inventory";
import { getLocations } from "api/locations";
import { getProductsWithAttributes } from "api/products";

import { MarkLocationAsInspected, MobileResetButton } from "components/Custom";
import { Button } from "components/Form";
import { TextInputWithKeyboard } from "components/FormElements";
import PageTitle from "components/PageTitle";
import Widget from "components/Widget";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";

import { QrCode2, SpaceBar } from "icons";

import { initialAPIListValue } from "utils/constants";
import { locationCodeValidation } from "utils/validations";

export default function LocationSKUValidation() {
  const { t } = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const theme = useTheme();
  const emptyDivRef = useRef(null);
  const [search, setSearch] = useState({
    location_code: "",
    product_sku: "",
  });
  const [locations, setLocations] = useState(initialAPIListValue);
  const [validation, setValidation] = useState({
    location: {},
    product: {},
  });
  const [keyboardState, setKeyboardState] = useState("none");
  const [countRedirect, setCountRedirect] = useState(false);

  const submitValidation = useCallback(() => {
    createLocationSKUValidation({
      body: {
        location_id: validation.location.id,
        product_id: validation.product.id,
      },
      setError: (e) => {
        resetForm();
        popupNotification({
          dispatch: layoutDispatch,
          message: e.message,
        });
      },
      setFunc: (res) => {
        popupNotification({
          dispatch: layoutDispatch,
          message: t("successful_operation"),
          status: "success",
        });
        const { should_check_location } = res;
        if (validation.product.id && should_check_location) {
          setCountRedirect(true);
          return;
        }
        resetForm();
      },
    });
  }, [layoutDispatch, t, validation]);

  useEffect(() => {
    if (validation.product.id) {
      submitValidation();
    }
  }, [validation, submitValidation]);

  useEffect(() => {
    function getKeyboardState(event) {
      if (event.key === "keyboardState") {
        setKeyboardState(event.newValue);
      }
    }
    window.addEventListener("storage", getKeyboardState);
    return () => {
      window.removeEventListener("storage", getKeyboardState);
    };
  }, []);

  useEffect(() => {
    if (keyboardState !== "none") {
      window.scrollBy(0, 130);
    } else {
      window.scrollBy(0, -130);
    }
  }, [keyboardState]);

  const checkLocation = () => {
    getLocations({
      setFunc: (res) => {
        const locations = res.items.results.filter(
          (l) => l.code === search.location_code,
        );
        if (locations.length === 1) {
          setValidation({
            ...validation,
            location: locations[0],
          });
        } else {
          setLocations(res);
        }
      },
      setError: (e) =>
        popupNotification({
          dispatch: layoutDispatch,
          message: e.error,
        }),
      params: { code: search.location_code },
    });
  };

  const setProductFromResponse = ({ items }) => {
    if (items.length === 1) {
      const { product_sku, product } = items[0];
      setValidation({
        ...validation,
        product: { sku: product_sku, id: product },
      });
    } else {
      const products = items.filter(
        (i) => i.product_sku === search.product_sku,
      );
      if (products && products.length === 1) {
        const { product_sku, product } = products[0];
        setValidation({
          ...validation,
          product: { sku: product_sku, id: product },
        });
      } else {
        popupNotification({
          dispatch: layoutDispatch,
          message: t("invalid_sku"),
        });
        setSearch({ ...search, product_sku: "" });
      }
    }
  };

  const getProductBySKU = () => {
    getProductsWithAttributes({
      setError: (e) =>
        popupNotification({
          dispatch: layoutDispatch,
          message: e.error,
        }),
      responseSetter: ({ results }) =>
        setProductFromResponse({ items: results }),
      params: {
        value_iexact: search.product_sku,
      },
    });
  };

  const resetForm = () => {
    setValidation({
      location: {},
      product: {},
    });
    setLocations(initialAPIListValue);
    setSearch({
      location_code: "",
      product_sku: "",
    });
  };

  return (
    <>
      <PageTitle
        title={t("sku_locations")}
        helpText={t("validate_product_locations")}
      />
      {countRedirect && <CycleCountDirectingDialog params={validation} />}
      <Grid container spacing={4}>
        <Grid item lg={12} xs={12}>
          <Widget>
            <Grid container spacing={4}>
              <Grid item lg={12} xs={12}>
                {!validation.location.code ? (
                  <TextInputWithKeyboard
                    size="medium"
                    name="location"
                    label={t("location")}
                    variant="outlined"
                    fullWidth
                    autoFocus
                    value={search.location_code}
                    onChange={({ target: { value } }) => {
                      let location_code = locationCodeValidation(value.trim());
                      if (!location_code) {
                        location_code = value.trim();
                      }
                      setSearch({
                        ...search,
                        location_code: location_code,
                      });
                    }}
                    onKeyPress={({ key }) =>
                      key === "Enter" ? checkLocation() : null
                    }
                    LeftIcon={SpaceBar}
                    hasBorder
                  />
                ) : (
                  <MarkLocationAsInspected
                    location_code={validation.location.code}
                    location_id={validation.location.id}
                    afterConfirm={resetForm}
                  />
                )}
                {!validation.location.code &&
                  locations.items &&
                  locations.items.results &&
                  locations.items.results.length > 0 && (
                    <List component="nav">
                      {locations.items.results.map((location) => (
                        <ListItem
                          key={location.id}
                          button
                          onClick={() =>
                            setValidation({ ...validation, location })
                          }
                        >
                          <ListItemText primary={location.code} />
                        </ListItem>
                      ))}
                    </List>
                  )}
              </Grid>
              {validation.location.code && (
                <Grid item lg={12} xs={12}>
                  <TextInputWithKeyboard
                    size="medium"
                    name="product"
                    label={t("product")}
                    variant="outlined"
                    fullWidth
                    autoFocus
                    value={search.product_sku}
                    onChange={({ target: { value } }) =>
                      setSearch({
                        ...search,
                        product_sku: value,
                      })
                    }
                    onKeyPress={({ key }) =>
                      key === "Enter" ? getProductBySKU() : null
                    }
                    LeftIcon={QrCode2}
                    hasBorder
                  />
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={submitValidation}
                    style={{
                      marginTop: 30,
                      backgroundColor: theme.palette.colors.brown,
                      color: theme.palette.colors.white,
                      width: "50%",
                    }}
                  >
                    {t("mark_empty_location")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <div style={{ minHeight: "330px" }} ref={emptyDivRef}></div>
        </Grid>
      </Grid>
      <MobileResetButton onClick={resetForm} />
    </>
  );
}

function CycleCountDirectingDialog({ params }) {
  const directToCountPage = ({ product, location }) => {
    let url = `/#/app/count?location_id=${location.id}&location_code=${location.code}`;
    if (product.id) {
      url += `&product_id=${product.id}&product_sku=${product.sku}`;
    }
    document.location.href = url;
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="directing-info-dialog"
      onClose={() => directToCountPage(params)}
    >
      <DialogTitle id="directing-info-dialog">
        Redirecting to the count page
      </DialogTitle>
      <DialogContent>
        <Typography>
          You should count the product on this location. Please click "Go To
          Count Page" button.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => directToCountPage(params)}
          color="primary"
        >
          Go to Count Page
        </Button>
      </DialogActions>
    </Dialog>
  );
}
