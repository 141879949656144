import {
  Archive,
  Archive2,
  Assignment2 as Assignment,
  AssignmentReturn,
  Autorenew,
  CallToAction,
  ChartSquareBar,
  ClipboardCheck,
  ClipboardList,
  Collection,
  ColorSwatch,
  Dashboard,
  DocumentReport,
  Eye,
  FilterCenterFocus,
  Globe,
  Group,
  History,
  Library,
  ListAlt,
  LocalShipping,
  LocationOn2 as LocationOn,
  Login,
  Logout1,
  Menu,
  Person,
  PresentationChartLine,
  Qrcode,
  Receipt,
  ReceiptLong,
  RotateRight,
  ShoppingCart,
  SwitchHorizontal,
  Sync,
  Template,
  TextSnippet,
  Timer,
  Truck,
  ViewGridAdd,
} from "icons";

export function menuItems() {
  const items = [
    {
      id: 1,
      label: "ecommerce",
      link: "#",
      icon: Globe,
      desktop: true,
      mobile: true,
      seller_visible: true,
      permission: "contenttypes.ecommerce",
      children: [
        {
          label: "Shipper DB",
          link: "/app/shipper-dashboard",
          icon: Eye,
          desktop: true,
          mobile: false,
        },
        {
          label: "Office DB",
          link: "/app/office-dashboard",
          icon: Library,
          desktop: true,
          mobile: false,
        },
        {
          label: "Transactions",
          link: "/app/transaction-report",
          icon: TextSnippet,
          desktop: true,
          mobile: false,
        },
        {
          label: "shipments",
          link: "/app/shipments",
          icon: Truck,
          seller_visible: true,
          desktop: true,
          mobile: true,
        },
        {
          label: "assign",
          link: "/app/ecommerce/assign",
          icon: ClipboardList,
          desktop: true,
          mobile: false,
          permission: "contenttypes.ecommerce.assign",
        },
        {
          label: "unassign",
          link: "/app/ecommerce/unassign",
          icon: SwitchHorizontal,
          desktop: true,
          mobile: false,
          permission: "contenttypes.ecommerce.assign",
        },
        {
          label: "picker_statistics",
          link: "/app/ecommerce/picker-statistics",
          icon: DocumentReport,
          desktop: true,
          mobile: false,
        },
        {
          label: "picker_performance",
          link: "/app/ecommerce/picker-performance",
          icon: Timer,
          desktop: false,
          mobile: false,
          permission: "contenttypes.ecommerce.picker_performance",
        },
        {
          label: "pick",
          link: "/app/ecommerce/pick",
          icon: ShoppingCart,
          desktop: false,
          mobile: true,
          permission: "contenttypes.ecommerce.pick",
        },
        {
          label: "ship",
          link: "/app/ecommerce/ship",
          icon: Archive,
          desktop: false,
          mobile: true,
          permission: "contenttypes.ecommerce.ship",
        },
      ],
    },
    {
      id: 2,
      label: "ltl",
      link: "#",
      icon: LocalShipping,
      desktop: true,
      mobile: true,
      seller_visible: true,
      permission: "contenttypes.ltl.menu",
      children: [
        {
          label: "shipments",
          link: "/app/ltl/shipments",
          icon: Collection,
          desktop: true,
          mobile: true,
          seller_visible: true,
          permission: "contenttypes.ltl.view",
        },
        {
          label: "pick_list",
          link: "/app/ltl/pick-list",
          icon: ClipboardList,
          desktop: false,
          mobile: true,
          permission: "contenttypes.ltl.pick_list",
        },
        {
          label: "Packaging",
          link: "/app/ltl/packaging",
          icon: Archive2,
          desktop: false,
          mobile: true,
          permission: "contenttypes.ltl.packaging",
        },
      ],
    },
    {
      id: 3,
      label: "inventory",
      link: "#",
      icon: ColorSwatch,
      desktop: true,
      mobile: true,
      seller_visible: true,
      permission: "",
      children: [
        {
          label: "dashboard",
          link: "/app/inventory-dashboard",
          icon: Eye,
          desktop: true,
          mobile: false,
        },
        {
          label: "inventory",
          link: "/app/inventory/list",
          icon: Menu,
          desktop: true,
          mobile: false,
          seller_visible: true,
        },
        {
          label: "snapshots",
          link: "/app/inventory-snapshot",
          icon: ReceiptLong,
          desktop: true,
          mobile: true,
          permission: "warehouse.view_inventorysnapshot",
        },
        {
          label: "inventory",
          link: "/app/inventory/list-mobile",
          icon: Menu,
          desktop: false,
          mobile: true,
        },
        {
          label: "receipt",
          link: "/app/receipt",
          icon: Receipt,
          desktop: true,
          mobile: false,
        },
        {
          label: "receiving_report",
          link: "/app/receiving-report",
          icon: PresentationChartLine,
          desktop: true,
          mobile: false,
          permission: "warehouse.view_inventorymovement",
        },
        {
          label: "Transfer",
          link: "/app/transfer",
          icon: SwitchHorizontal,
          desktop: false,
          mobile: true,
        },
        {
          label: "Container Transfer",
          link: "/app/receipt-transfer",
          icon: Login,
          desktop: false,
          mobile: true,
        },
        {
          label: "cycle_count",
          link: "/app/count",
          icon: Sync,
          desktop: false,
          mobile: true,
          permission: "warehouse.count",
        },
        {
          label: "cycle_counts",
          link: "/app/cycle-counts",
          icon: Sync,
          desktop: true,
          mobile: false,
          permission: "contenttypes.inventory.cycle_counts_list",
        },
        {
          label: "item_count",
          link: "/app/item-count",
          icon: RotateRight,
          desktop: false,
          mobile: true,
          permission: "warehouse.item_count",
        },
        {
          label: "item_counts",
          link: "/app/item-counts-group",
          icon: ListAlt,
          desktop: true,
          mobile: false,
          permission: "warehouse.item_manager",
        },
        {
          label: "sku_locations",
          link: "/app/location-sku-report",
          icon: Collection,
          desktop: true,
          mobile: false,
        },
        {
          label: "sku_locations",
          link: "/app/location-sku-validation",
          icon: Collection,
          desktop: false,
          mobile: true,
        },
        {
          label: "duplicates",
          link: "/app/duplicate-items",
          icon: CallToAction,
          desktop: true,
          mobile: false,
        },
        {
          label: "consolidations",
          link: "/app/product-consolidations",
          icon: Autorenew,
          desktop: false,
          mobile: false,
        },
        {
          label: "movements",
          link: "/app/movement-history",
          icon: History,
          desktop: true,
          mobile: false,
          permission: "warehouse.view_inventorymovement",
        },
        {
          label: "containers",
          link: "/app/containers",
          icon: Dashboard,
          desktop: true,
          mobile: false,
        },
        {
          label: "Stock",
          link: "/app/stock",
          icon: ViewGridAdd,
          desktop: true,
          mobile: false,
          permission: "stock.view_classificationhistory",
        },
      ],
    },
    {
      id: 4,
      label: "others",
      link: "#",
      icon: Archive,
      desktop: true,
      mobile: true,
      seller_visible: true,
      children: [
        {
          label: "returns",
          link: "/app/returns",
          icon: AssignmentReturn,
          desktop: true,
          mobile: false,
          seller_visible: true,
          permission: "shipment.view_return",
        },
        {
          label: "returns",
          link: "/app/create-return",
          icon: AssignmentReturn,
          desktop: false,
          mobile: true,
          permission: "shipment.add_return",
        },
        {
          label: "locations",
          link: "/app/locations",
          icon: LocationOn,
          desktop: true,
          mobile: true,
        },
        {
          label: "products",
          link: "/app/products",
          icon: FilterCenterFocus,
          desktop: true,
          mobile: true,
          seller_visible: true,
        },
        {
          label: "Validate XRefs",
          link: "/app/validate-cross-references",
          icon: ClipboardCheck,
          desktop: true,
          mobile: false,
          permission: "product.view_productattributerequest",
        },
        {
          label: "label_maker",
          link: "/app/label-maker",
          icon: Qrcode,
          desktop: true,
          mobile: false,
          permission: "contenttypes.label_maker",
        },
        {
          label: "castlegate_pdf",
          link: "/app/castlegate-pdf-generator",
          icon: Template,
          desktop: true,
          mobile: false,
          permission: "contenttypes.castlegate_pdf",
        },
        {
          label: "users",
          link: "/app/user",
          icon: Group,
          desktop: true,
          mobile: false,
          permission: "contenttypes.user_management",
        },
        {
          label: "receipt",
          link: "/app/receipt",
          icon: Receipt,
          desktop: false,
          mobile: false,
          permission: "receipt.view_receipt",
        },
        {
          label: "my_tasks",
          link: "/app/my-work",
          icon: Assignment,
          desktop: true,
          mobile: true,
        },
        {
          label: "promotions",
          link: "/app/promotions",
          icon: ChartSquareBar,
          desktop: true,
          mobile: false,
          permission: "order.view_promotion",
        },
        {
          label: "deliver_buggy",
          link: "#",
          icon: AssignmentReturn,
          desktop: false,
          mobile: true,
          clickFunction: () => {
            localStorage.removeItem("buggyId");
            localStorage.removeItem("buggyCode");
            localStorage.removeItem("pickedLabels");
          },
          popupMessage: "deliver_buggy_to_dock",
          popupCloseFunction: () => window.location.reload(),
        },
        {
          label: "profile",
          link: "/app/profile",
          icon: Person,
          desktop: false,
          mobile: true,
          seller_visible: true,
        },
        {
          label: "Logout",
          link: "#",
          icon: Logout1,
          desktop: false,
          mobile: true,
          seller_visible: true,
          clickFunction: () => {
            localStorage.clear();
            window.location.reload();
          },
        },
      ],
    },
  ];

  return { items };
}
