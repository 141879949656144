import React, { useEffect, useMemo, useRef, useState } from "react";

import _ from "lodash";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { createCycleCount, getInventories } from "api/inventory";
import { getAssignedLocations, getLocations } from "api/locations";
import { getProductsWithAttributes } from "api/products";

import { MarkLocationAsInspected, MobileResetButton } from "components/Custom";
import { Button } from "components/Form";
import { TextInputWithKeyboard } from "components/FormElements";
import PageTitle from "components/PageTitle";
import Widget from "components/Widget";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";

import { QrCode2, TextSnippet, SpaceBar, MultipleStop } from "icons";

import { initialAPIListValue } from "utils/constants";
import { separateQtyFromValue } from "utils/string";
import {
  locationCodeValidation,
  productAttributeValidation,
} from "utils/validations";

const initialCountData = {
  location: {},
  product: {},
  quantity: 0,
  assigned_location: null,
};

export default function CycleCount({ history }) {
  const { t } = useTranslation();
  const loc = useLocation();
  const theme = useTheme();
  const queryString = useMemo(() => new URLSearchParams(loc.search), [
    loc.search,
  ]);
  const layoutDispatch = useLayoutDispatch();
  const emptyDivRef = useRef(null);

  const [search, setSearch] = useState({
    location_code: "",
    product_sku: "",
    quantity: "",
  });
  const [locations, setLocations] = useState(initialAPIListValue);
  const [count, setCount] = useState(initialCountData);
  const [assignedLocations, setAssignedLocations] = useState(
    initialAPIListValue,
  );

  const [keyboardState, setKeyboardState] = useState("none");
  const [redirectSKULoc, setRedirectSKULoc] = useState(false);
  const [reversed, setReversed] = useState(false);

  const getLocationsByCode = () => {
    setLocations(initialAPIListValue);
    let location_code = locationCodeValidation(search.location_code.trim());
    if (!location_code) {
      location_code = search.location_code.trim();
    }

    getLocations({
      setError: (e) =>
        popupNotification({
          dispatch: layoutDispatch,
          message: e.error,
        }),
      setFunc: (res) => {
        const locations = res.items.filter(
          (l) => l.code === search.location_code,
        );
        if (locations.length === 1) {
          const matchAssignedLocation = assignedLocations.items.find(
            (al) => al.location.id === locations[0].id,
          );
          setCount({
            ...count,
            location: locations[0],
            assigned_location: matchAssignedLocation,
          });
        } else if (locations.length === 0) {
          setSearch((prev) => ({ ...prev, location_code: "" }));
          popupNotification({
            dispatch: layoutDispatch,
            message: "Invalid location",
          });
        } else {
          setLocations(locations);
        }
      },
      params: {
        code: location_code,
      },
      paginated: true,
    });
  };

  // Works when directed from SKULoc page
  useEffect(() => {
    const location_id = queryString.get("location_id");
    const location_code = queryString.get("location_code");

    if (location_code && location_id) {
      const product_id = queryString.get("product_id");
      const product_sku = queryString.get("product_sku");
      const countData = {
        location: { id: location_id, code: location_code },
        product: { id: product_id, sku: product_sku },
      };

      setRedirectSKULoc(true);
      setSearch((prev) => ({
        ...prev,
        location_code,
        product_sku,
      }));

      if (product_id) {
        setCount((prev) => ({
          ...prev,
          ...countData,
        }));
        return;
      }

      getInventories({
        params: {
          location__code: location_code,
        },
        responseSetter: (res) => {
          const { results } = res;
          if (results.length) {
            const product = results[0].product;
            countData["product"] = { id: product.id, sku: product.sku };
          }
          setCount((prev) => ({
            ...prev,
            ...countData,
          }));
        },
      });
    }
  }, [queryString]);

  useEffect(() => {
    function getKeyboardState(event) {
      if (event.key === "keyboardState") {
        setKeyboardState(event.newValue);
      }
    }
    window.addEventListener("storage", getKeyboardState);
    return () => {
      window.removeEventListener("storage", getKeyboardState);
    };
  }, []);

  useEffect(() => {
    if (keyboardState !== "none") {
      window.scrollBy(0, 400);
    } else {
      window.scrollBy(0, -400);
    }
  }, [keyboardState]);

  useEffect(() => {
    if (assignedLocations.isFetching) {
      getAssignedLocations({
        params: {
          is_counted: false,
          is_cancelled: false,
          limit: 1000,
        },
        setFunc: setAssignedLocations,
      });
    }
  }, [assignedLocations.isFetching]);

  const setProductFromResponse = ({ sku, items }) => {
    if (items.length === 1) {
      const { product_sku, product } = items[0];
      setCount({ ...count, product: { sku: product_sku, id: product } });
    } else {
      const products = items.filter((i) => i.product_sku === sku);
      if (products && products.length === 1) {
        const { product_sku, product } = products[0];
        setCount({ ...count, product: { sku: product_sku, id: product } });
      } else {
        popupNotification({
          dispatch: layoutDispatch,
          message: t("invalid_sku"),
        });
        setSearch({ ...search, product_sku: "" });
      }
    }
  };

  const getProductBySKU = () => {
    const { value, qty } = separateQtyFromValue(search.product_sku);
    const sku = productAttributeValidation(value);
    if (!sku) {
      setSearch({ ...search, product_sku: "" });
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Invalid SKU",
      });
    }
    if (qty) {
      setSearch({ ...search, quantity: qty, product_sku: sku });
    }
    getProductsWithAttributes({
      setError: (e) =>
        popupNotification({
          dispatch: layoutDispatch,
          message: e.error,
        }),
      responseSetter: ({ results, count }) =>
        setProductFromResponse({
          sku: sku,
          items: results,
          isFetching: false,
          count: count,
        }),
      params: {
        value_iexact: sku,
      },
    });
  };

  const resetForm = () => {
    setCount(initialCountData);
    setLocations(initialAPIListValue);
    setSearch({
      location_code: "",
      product_sku: "",
      quantity: "",
    });
  };

  const submitCount = () => {
    const quantity = parseInt(search.quantity);
    if (
      search.product_sku &&
      (quantity < 0 || Number.isNaN(quantity) || quantity > 999)
    ) {
      setSearch({
        ...search,
        quantity: "",
      });
      return popupNotification({
        dispatch: layoutDispatch,
        message: "Invalid quantity",
      });
    }
    createCycleCount({
      body: {
        location_id: count.location.id,
        product_id: count.product.id,
        count: search.product_sku ? quantity : 0,
      },
      setError: (error) =>
        popupNotification({
          dispatch: layoutDispatch,
          message: error.message,
        }), // only error here should be server error
      setFunc: () => {
        popupNotification({
          dispatch: layoutDispatch,
          message: t("successful_operation"),
          status: "success",
        });
        _.remove(
          assignedLocations.items,
          (item) => item.location.id === count.location.id,
        );
        setAssignedLocations({
          items: assignedLocations.items,
          isFetching: false,
          count: assignedLocations.items.length,
        });

        if (redirectSKULoc) {
          history.push("location-sku-validation");
        }
      },
    });

    resetForm();
  };

  const afterInspectConfirm = () => {
    _.remove(
      assignedLocations.items,
      (item) => item.location.id === count.location.id,
    );
    setAssignedLocations(assignedLocations);
    resetForm();
  };

  const notCountedLocations = assignedLocations.items
    .filter((al) => !al.is_counted)
    .sort((a, b) => a.location.code.localeCompare(b.location.code));

  if (reversed) {
    _.reverse(notCountedLocations);
  }

  return (
    <>
      <PageTitle title={t("cycle_count")} />
      <Grid container spacing={4}>
        <Grid item lg={12} xs={12}>
          <Widget>
            <Grid container spacing={4}>
              <Grid item lg={12} xs={12} style={{ padding: "0 1rem" }}>
                {!count.location.code && (
                  <>
                    <Grid container alignItems="center">
                      <Grid
                        item
                        xs={notCountedLocations.length > 0 ? 10 : 12}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <TextInputWithKeyboard
                          size="medium"
                          name="location"
                          label={t("location")}
                          variant="outlined"
                          style={{ width: "70%" }}
                          autoFocus
                          value={search.location_code}
                          onChange={({ target: { value } }) => {
                            let location_code = locationCodeValidation(
                              value.trim(),
                            );
                            if (!location_code) {
                              location_code = value.trim();
                            }
                            setSearch({
                              ...search,
                              location_code: location_code,
                            });
                          }}
                          onKeyPress={({ key }) =>
                            key === "Enter" ? getLocationsByCode() : null
                          }
                          placeholder={
                            notCountedLocations.length
                              ? notCountedLocations[0].location.code
                              : ""
                          }
                          LeftIcon={SpaceBar}
                          hasBorder
                        />
                      </Grid>
                      {notCountedLocations.length > 0 && (
                        <Grid item xs={2}>
                          <Button
                            color="primary"
                            style={{
                              position: "absolute",
                              minWidth: "35px",
                              minHeight: "35px",
                              width: "35px",
                              height: "35px",
                              padding: "0",
                              right: "2em",
                            }}
                            onClick={() => {
                              setReversed(!reversed);
                            }}
                          >
                            <MultipleStop color="white" />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
                {!count.location.code &&
                  locations.items &&
                  locations.items.length > 0 && (
                    <List component="nav">
                      {locations.items.map((location) => (
                        <ListItem
                          key={location.id}
                          button
                          onClick={() => setCount({ ...count, location })}
                        >
                          <ListItemText primary={location.code} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                {count.location.code && (
                  <MarkLocationAsInspected
                    location_code={count.location.code}
                    location_id={count.location.id}
                    assigned_location_id={
                      count.assigned_location
                        ? count.assigned_location.id
                        : null
                    }
                    afterConfirm={afterInspectConfirm}
                  />
                )}
              </Grid>
              {count.location.code && (
                <Grid item lg={12} xs={12} style={{ padding: "0 1rem" }}>
                  {!count.product.sku ? (
                    <>
                      <TextInputWithKeyboard
                        size="medium"
                        name="product"
                        label={t("product")}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        value={search.product_sku}
                        onChange={({ target: { value } }) =>
                          setSearch({
                            ...search,
                            product_sku: value,
                          })
                        }
                        onKeyPress={({ key }) =>
                          key === "Enter" ? getProductBySKU() : null
                        }
                        LeftIcon={QrCode2}
                        hasBorder
                      />
                      <Button
                        variant="contained"
                        onClick={submitCount}
                        style={{
                          marginTop: 30,
                          backgroundColor: theme.palette.colors.brown,
                          color: theme.palette.colors.white,
                          width: "50%",
                        }}
                      >
                        {t("mark_empty_location")}
                      </Button>
                    </>
                  ) : (
                    <Typography
                      variant="h6"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <span style={{ paddingTop: "3px" }}>
                        SKU: {count.product.sku}
                      </span>
                    </Typography>
                  )}
                </Grid>
              )}
              {count.product.sku && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextInputWithKeyboard
                    type="number"
                    size="medium"
                    name="quantity"
                    inputMode="numeric"
                    label={t("quantity")}
                    variant="outlined"
                    error={
                      Number.isNaN(parseInt(search.quantity)) ||
                      parseInt(search.quantity) < 0 ||
                      parseInt(search.quantity) > 999
                    }
                    fullWidth
                    autoFocus
                    value={search.quantity}
                    onChange={({ target: { value } }) =>
                      setSearch({
                        ...search,
                        quantity: /^\d+$/.test(value) ? value : "",
                      })
                    }
                    onKeyPress={({ key }) =>
                      key === "Enter" ? submitCount() : null
                    }
                    LeftIcon={TextSnippet}
                    hasBorder
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={submitCount}
                  >
                    {t("submit")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <div style={{ minHeight: "360px" }} ref={emptyDivRef}></div>
        </Grid>
      </Grid>
      <MobileResetButton onClick={resetForm} />
    </>
  );
}
