import React, { createContext, useContext, useReducer } from "react";

import {
  getWithExpiry,
  setWithExpiry,
  DAYINMILLISECONDS,
  HOURINMILLISECONDS,
} from "../utils/storage";

var UserStateContext = createContext();
var UserDispatchContext = createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "SET_PERMISSIONS":
      return {
        ...state,
        permissions: action.permissions,
        isAuthenticated: true,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = useReducer(userReducer, {
    isAuthenticated: !!getWithExpiry("access"),
    permissions: [],
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  validToken,
  signOut,
};

async function validToken(dispatch, access) {
  const url = `${process.env.REACT_APP_API_URL}api/custom/get-permissions/`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
  };
  const res = await fetch(url, options);
  if (res.status === 200) {
    const json = await res.json();
    dispatch({ type: "SET_PERMISSIONS", permissions: json.permissions });
  } else {
    localStorage.removeItem("access");
  }
}

async function loginUser(dispatch, login, password, setIsLoading, setError) {
  const url = `${process.env.REACT_APP_API_URL}api/token/`;
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "requested-by": "wms",
    },
    body: JSON.stringify({
      username: login.toLowerCase(),
      password: password,
    }),
  };
  setIsLoading(true);

  const fetchData = async (url, options) => {
    try {
      const res = await fetch(url, options);
      if (res.status === 200) {
        const json = await res.json();
        setWithExpiry("access", json.access, HOURINMILLISECONDS);
        setWithExpiry("refresh", json.refresh, DAYINMILLISECONDS);
        dispatch({ type: "SET_PERMISSIONS", permissions: json.permissions });
      } else {
        setError("Login failed, check your credentials");
        setIsLoading(false);
      }
    } catch (error) {
      setError("Server unavailable, admins notified.");
      setIsLoading(false);
    }
  };
  await fetchData(url, options);
}

function signOut(dispatch) {
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  window.location.href = "/#/login";  // history.push errors
}
