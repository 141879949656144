import React, { useCallback, useEffect, useState } from "react";

import _, { debounce } from "lodash";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { getAdjustments } from "api/adjustment";
import {
  approveCycleCounts,
  cancelCycleCounts,
  getCycleCounts,
  getCycleCountSummary,
} from "api/inventory";
import { getTransfers } from "api/transfer";

import { InternalLink } from "components/Custom/Links";
import { QuantityResult, StatusLabel } from "components/Custom";
import {
  Button,
  InputContainer,
  SwitchButton,
  SwitchButtonGroup,
} from "components/Form";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";
import { popupNotification, useLayoutDispatch } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import { initialAPIListValue } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { timezoneFormat } from "utils/date";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";
import { toTitleCase } from "utils/string";

import { ApproveModal, CancelModal } from "./CycleCountDialogs";
import CycleCountFilter from "./CycleCountFilter";
import { Assignment2 } from "icons";

export const cells = [
  {
    id: "location__code",
    label: "location",
    render: (r) => {
      const isInactive = r.location.is_deleted === true;
      return (
        <StatusLabel
          status={isInactive ? "Cancelled" : "Open"}
          text={r.location.code}
          altText={isInactive ? "Inactive" : ""}
        />
      );
    },
    sortable: true,
  },
  {
    id: "product__sku",
    label: "product_sku",
    exportLabel: "Inventory Id",
    render: (r) => {
      const isInactive = r.product.status === "Inactive";
      return (
        <StatusLabel
          status={isInactive ? "Cancelled" : "Open"}
          text={r.product.sku}
          altText={isInactive ? "Inactive" : ""}
        />
      );
    },
    sortable: true,
  },
  {
    id: "qty",
    info: "Before Qty. > Counted Qty. (Difference)",
    render: (r) => {
      const countQtyText = `${r.old_count} > ${r.count} (${r.difference})`;
      if (_.every([r.old_counts, r.counts, r.differences])) {
        return (
          <>
            <div>{countQtyText}</div>
            {r.old_counts.map((oc, i) => (
              <div key={`old_counts_${i}`}>
                {oc} {">"} {r.counts[i]} ({r.differences[i]})
              </div>
            ))}
          </>
        );
      }
      return countQtyText;
    },
  },
  {
    id: "created_by__first_name",
    label: "created_by",
    render: (r) => {
      if (r.usernames) {
        return (
          <>
            <div>{toTitleCase(r.created_by.username)}</div>
            {r.usernames.map(toTitleCase).map((un, i) => (
              <div key={`usernames_${un}_${i}`}>{un}</div>
            ))}
          </>
        );
      }
      return toTitleCase(r.created_by.username);
    },
    sortable: true,
  },
  {
    id: "created_at",
    render: (r) => {
      if (r.times) {
        return (
          <>
            <div>{timezoneFormat(r.created_at)}</div>
            {r.times.map((t, i) => (
              <div key={`times_${t}_${i}`}>{t}</div>
            ))}
          </>
        );
      }
      return timezoneFormat(r.created_at);
    },
    sortable: true,
  },
];

export default function CycleCounts() {
  const { permissions } = useUserState();
  const hasListPermission = permissions?.includes(
    "contenttypes.inventory.cycle_counts_list",
  );
  const history = useHistory();
  const layoutDispatch = useLayoutDispatch();
  const { t } = useTranslation();
  const { related_product } = parseJwt(getWithExpiry("access"));
  const initialParams = {
    start_date: "",
    end_date: "",
    product_sku: "",
    location_code_start: "",
    location_is_deleted: "",
    location_position: "",
    status: "Open",
    show_only_different: "True",
    is_published: "False",
    created_by_username: "",
    product_type: related_product || 1,
    should_recount: false,
    inactive: "",
  };
  const [cycleCounts, setCycleCounts] = useState(initialAPIListValue);
  const [params, setParams] = useState(initialParams);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [exportDisabled, setExportDisabled] = useState(false);
  const [selectedCycleCounts, setSelectedCycleCounts] = useState([]);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cycleCountSummary, setCycleCountSummary] = useState({
    recount: 0,
    inactive: 0,
  });
  const [summaryIsLoading, setSummaryIsLoading] = useState(true);
  const [notPublishedContent, setNotPublishedContent] = useState({
    transfers: 0,
    adjustments: 0,
  });
  const [headerCounts, setHeaderCounts] = useState({});

  const cycleCountSummaryRequest = () => {
    getCycleCountSummary({
      responseSetter: (res) => {
        const {
          summary: { recount, inactive },
        } = res;
        setCycleCountSummary({
          recount,
          inactive,
        });
        setSummaryIsLoading(false);
      },
    });
  };

  useEffect(() => {
    cycleCountSummaryRequest();
  }, []);

  useEffect(() => {
    if (cycleCounts.isFetching && hasListPermission) {
      const filters = {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        ...params,
        status: params.is_published !== "False" ? "" : params.status,
        should_recount:
          params.inactive || params.is_published !== "False"
            ? ""
            : params.should_recount,
      };

      if (orderDirection && orderBy) {
        filters.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }

      getCycleCounts({
        params: filters,
        setFunc: (res) => {
          setCycleCounts(res);
          setHeaderCounts(res.customCounts);
        },
        paginated: true,
      });
    }
  }, [
    cycleCounts.isFetching,
    params,
    rowsPerPage,
    page,
    hasListPermission,
    orderDirection,
    orderBy,
  ]);

  useEffect(() => {
    getTransfers({
      params: {
        is_published: false,
        type: "count",
        limit: 1, // we just need the total
      },
      paginated: true,
      responseSetter: (res) => {
        setNotPublishedContent((prev) => ({ ...prev, transfers: res.count }));
      },
    });
    getAdjustments({
      params: {
        is_published: false,
        type: "count",
        limit: 1, // we just need the total
      },
      paginated: true,
      responseSetter: (res) => {
        setNotPublishedContent((prev) => ({ ...prev, adjustments: res.count }));
      },
    });
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setCycleCounts({ ...cycleCounts, isFetching: true });
  };

  const handleChangeFilterInput = (prop) => (event) => {
    setParams({ ...params, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    setCycleCounts({
      ...cycleCounts,
      isFetching: true,
    });
  };

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setCycleCounts({ ...cycleCounts, isFetching: true });
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `Cycle-Counts-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, [
        {
          exportLabel: "Location",
          render: (r) => r.location.code,
        },
        {
          exportLabel: "Inventory ID",
          render: (r) => r.product.sku,
        },
        {
          exportLabel: "Before",
          render: (r) =>
            r.old_counts
              ? `${r.old_count} | ${r.old_counts.join(", ")}`
              : r.old_count,
        },
        {
          exportLabel: "Counted",
          render: (r) =>
            r.counts ? `${r.count} | ${r.counts.join(", ")}` : r.count,
        },
        {
          exportLabel: "Difference",
          render: (r) =>
            r.differences
              ? `${r.difference} | ${r.differences.join(", ")}`
              : r.difference,
        },
        {
          exportLabel: "Created By",
          render: (r) =>
            r.usernames
              ? `${toTitleCase(r.created_by.username)} | ${r.usernames
                  .map(toTitleCase)
                  .join(", ")}`
              : toTitleCase(r.created_by.username),
        },
        {
          exportLabel: "Created At",
          render: (r) => {
            if (r.times) {
              return `${timezoneFormat(r.created_at)} | ${r.times.join(", ")}`;
            }
            return timezoneFormat(r.created_at);
          },
          sortable: true,
        },
        {
          exportLabel: "Inactive",
          render: (r) => (r.product.status === "Inactive" ? "Yes" : "No"),
        },
      ]),
      setExportDisabled,
    );
  };

  const handleApprove = () => {
    setIsLoading(true);
    const cycleCountIds = selectedCycleCounts.map(
      (cycleCount) => cycleCount.id,
    );
    approveCycleCounts({
      body: { cycle_count_ids: cycleCountIds },
      setFunc: () => {
        resetState();
        cycleCountSummaryRequest();
        popupNotification({
          dispatch: layoutDispatch,
          message: t("successful_operation"),
          status: "success",
        });
      },
      setError: (error) => {
        resetState();
        popupNotification({
          dispatch: layoutDispatch,
          message: error.message,
          status: "error",
        });
      },
    });
  };

  const handleCancel = () => {
    setIsLoading(true);
    const cycleCountIds = selectedCycleCounts.map(
      (cycleCount) => cycleCount.id,
    );
    cancelCycleCounts({
      body: { cycle_count_ids: cycleCountIds },
      setFunc: () => {
        resetState();
        popupNotification({
          dispatch: layoutDispatch,
          message: t("successful_operation"),
          status: "success",
        });
      },
      setError: (error) => {
        resetState();
        popupNotification({
          dispatch: layoutDispatch,
          message: error.message,
          status: "error",
        });
      },
    });
  };

  const resetState = () => {
    setIsLoading(false);
    setSelectedCycleCounts([]);
    setApproveModalOpen(false);
    setCancelModalOpen(false);
    setCycleCounts({ ...cycleCounts, isFetching: true });
  };

  const handleExportData = () => {
    if (cycleCounts.items.length === cycleCounts.count) {
      exportData(cycleCounts.items);
    } else {
      getCycleCounts({
        params: {
          ...params,
          limit: cycleCounts.count,
          status: params.is_published !== "False" ? "" : params.status,
          should_recount:
            params.inactive || params.is_published !== "False"
              ? ""
              : params.should_recount,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  const clearHandler = (input) => {
    setParams({ ...params, [input]: "" });
    setPage(0);
    setCycleCounts({
      ...cycleCounts,
      isFetching: true,
    });
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, params);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setCycleCounts((prev) => ({ ...prev, isFetching: true }));
    }, 500),
    [],
  );

  const handleChangeSwitchButton = (row) => {
    const [key, value] = row.split("|");
    setPage(0);
    setParams({ ...initialParams, [key]: value });
    setCycleCounts({ ...cycleCounts, isFetching: true });
  };

  const disableApproveAndCancelButtons =
    selectedCycleCounts.length === 0 || isLoading;

  const visibleNotPublishedButtonCount =
    Boolean(notPublishedContent.transfers) +
    Boolean(notPublishedContent.adjustments);

  return (
    <>
      <PageTitle title={t("cycle_counts")} />
      <InputContainer>
        <CycleCountFilter
          clearAdornment={clearAdornment}
          handleChangeFilterInput={handleChangeFilterInput}
          params={params}
          setParams={setParams}
          debouncedSearchHandler={debouncedSearchHandler}
        >
          <>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Button
                variant="contained"
                color="danger"
                disabled={!!disableApproveAndCancelButtons}
                onClick={() => {
                  setCancelModalOpen(true);
                }}
              >
                {isLoading && <CircularProgress color="white" size={20} />}
                {t("Cancel")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Button
                variant="contained"
                color="blue"
                onClick={() => {
                  setApproveModalOpen(true);
                }}
                disabled={disableApproveAndCancelButtons}
              >
                {isLoading && <CircularProgress color="white" size={20} />}
                {t("Approve")}
              </Button>
            </Grid>
          </>
        </CycleCountFilter>
      </InputContainer>
      {summaryIsLoading ? (
        <CircularProgress />
      ) : (
        <Grid
          container
          spacing={4}
          style={{
            backgroundColor: "",
            textAlign: "center",
            display: "flex",
            lineHeight: 1.25,
          }}
        >
          <SwitchButtonGroup
            xs={12}
            sm={12}
            md={12}
            lg={2}
            defaultValue={""}
            clearEnabled={true}
            onChange={handleChangeSwitchButton}
          >
            <SwitchButton value="should_recount|true" xs={6}>
              <QuantityResult
                label="Recount"
                value={cycleCountSummary.recount}
              />
            </SwitchButton>
            <SwitchButton value="inactive|true" xs={6}>
              <QuantityResult
                label="Inactive"
                value={cycleCountSummary.inactive}
              />
            </SwitchButton>
          </SwitchButtonGroup>
          <Grid item xs={12} md={4} lg={2} alignItems="center">
            <Button
              onClick={() => {
                history.push("/app/count/assign");
              }}
              color="blue"
            >
              <Assignment2 color="white" />
              Assign Counts
            </Button>
          </Grid>
          <Box
            component={Grid}
            item
            lg={8 - visibleNotPublishedButtonCount}
            display={{
              xs: "none",
              sm: "block",
              md: "none",
              lg: "block",
            }}
            style={{ borderRight: "none" }}
          />
          {notPublishedContent.transfers || notPublishedContent.adjustments ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={visibleNotPublishedButtonCount}
            >
              {notPublishedContent.transfers ? (
                <span
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "3rem",
                    border: "1px solid blue",
                    backgroundColor: "white",
                    cursor: "pointer",
                  }}
                >
                  <InternalLink
                    lg={12 / visibleNotPublishedButtonCount}
                    to="/app/transfers?type=count"
                    target="_blank"
                    style={{
                      color: "blue",
                      textDecoration: "none",
                    }}
                  >
                    <QuantityResult
                      label="Transfer"
                      value={notPublishedContent.transfers}
                      title="Not published transfers"
                    />
                  </InternalLink>
                </span>
              ) : null}
              {notPublishedContent.adjustments ? (
                <span
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "3rem",
                    border: "1px solid blue",
                    backgroundColor: "white",
                    cursor: "pointer",
                  }}
                >
                  <InternalLink
                    lg={12 / visibleNotPublishedButtonCount}
                    to="/app/adjustments?type=count"
                    target="_blank"
                    style={{
                      color: "blue",
                      textDecoration: "none",
                    }}
                  >
                    <QuantityResult
                      label="Adjustment"
                      value={notPublishedContent.adjustments}
                      title="Not published adjustments"
                    />
                  </InternalLink>
                </span>
              ) : null}
            </Grid>
          ) : null}
        </Grid>
      )}
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            headers={cells || []}
            headerCounts={headerCounts}
            filters={params}
            data={cycleCounts}
            rowsPerPage={rowsPerPage}
            page={page}
            hasCheckBox={true}
            selected={selectedCycleCounts}
            setSelected={setSelectedCycleCounts}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleExportData={handleExportData}
            exportDisabled={exportDisabled}
            setExportDisabled={setExportDisabled}
            sortActions={{ onSort, orderBy, orderDirection }}
          />
        </Grid>
      </Grid>
      <ApproveModal
        open={approveModalOpen}
        isLoading={isLoading}
        onClose={() => {
          setApproveModalOpen(false);
        }}
        onApprove={handleApprove}
      />
      <CancelModal
        open={cancelModalOpen}
        isLoading={isLoading}
        onClose={() => {
          setCancelModalOpen(false);
        }}
        onCancel={handleCancel}
      />
    </>
  );
}
