import React, { useCallback, useEffect, useState } from "react";

import { debounce } from "lodash";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { getInventories } from "api/inventory";

import { ProductTypeFilter } from "components/Custom";
import { Checkbox, Dropdown, InputContainer } from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import { QrCode2, FormatListNumbered, SpaceBar } from "icons";

import {
  initialAPIListValue,
  LocationTypes,
  ProductTypes,
} from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import parseJwt from "utils/jwt";
import { getWithExpiry } from "utils/storage";

export default function Inventory(props) {
  const { t } = useTranslation();
  const { is_manager, related_product, seller_id } = parseJwt(
    getWithExpiry("access"),
  );
  const layoutDispatch = useLayoutDispatch();

  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [inventory, setInventory] = useState(initialAPIListValue);
  const [search, setSearch] = useState({
    product__product_type: related_product || 1,
    location__location_type: "",
    product_sku_startswith: "",
    location__code: "",
    seller_id: seller_id === 1 ? "" : seller_id,
  });
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("location__code");
  const [exportDisabled, setExportDisabled] = useState(false);
  const [headerCounts, setHeaderCounts] = useState({});
  const { permissions } = useUserState();

  const cells = [
    {
      id: "location__code",
      label: "location",
      render: (r) => r.location.code,
      sortable: true,
    },
    {
      id: "product__sku",
      label: "product_sku",
      exportLabel: "Inventory Id",
      render: (r) => r.product.sku,
      sortable: true,
    },
    {
      id: "qty",
      exportLabel: "Quantity",
      sortable: true,
      render: (r) => r.qty,
    },
    { id: "size", render: (r) => r.product.short_size },
    {
      id: "area",
      exportLabel: "Area",
      sortable: true,
      render: (r) => (r.product.area_sqrmtr * r.qty).toFixed(2),
    },
  ];

  useEffect(() => {
    if (inventory.isFetching && (is_manager || search.product__product_type)) {
      let params = {
        ...search,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      };
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getInventories({
        params,
        setError,
        setFunc: (res) => {
          setInventory(res);
          setHeaderCounts(res.customCounts);
        },
        paginated: true,
      });
    }
  }, [
    rowsPerPage,
    page,
    search,
    inventory.isFetching,
    is_manager,
    orderDirection,
    orderBy,
  ]);

  useEffect(() => {
    if (error) {
      popupNotification({
        dispatch: layoutDispatch,
        message: error,
      });
      setError(false);
    }
  }, [error, layoutDispatch]);

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setInventory({ ...inventory, isFetching: true });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setInventory({ ...inventory, isFetching: true });
  };

  const typeControl = (prop, value) => {
    const { product__product_type, location__location_type } = search;
    let message = "";
    if (prop === "location__location_type") {
      if (
        (value === 1 && product__product_type === 2) ||
        (value === 2 && product__product_type === 1)
      ) {
        message = `You are not allowed to see ${
          LocationTypes.find((l) => l.value === value).label
        }`;
      }
    }

    if (prop === "product__product_type") {
      if (
        (value === 1 && location__location_type === 2) ||
        (value === 2 && location__location_type === 1)
      ) {
        message = `You are not allowed to see ${
          ProductTypes.find((l) => l.value === value).label
        }`;
      }
    }

    if (!message) {
      return false;
    }

    popupNotification({
      dispatch: layoutDispatch,
      message,
    });
    return true;
  };

  const handleChangeFilterInput = (prop) => ({ target: { value } }) => {
    const error = typeControl(prop, value);
    if (error) {
      return;
    }
    setSearch({ ...search, [prop]: value });
    debouncedSearchHandler();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setInventory({
      items: inventory.items,
      isFetching: true,
      count: inventory.count,
    });
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `Inventory-${today.toISOString()}`,
      true,
      convertDataToTableView(data, cells),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    if (inventory.items.length === inventory.count) {
      exportData(inventory.items);
    } else {
      getInventories({
        params: {
          limit: inventory.count,
          ...search,
        },
        setFunc: ({ items: { results } }) => exportData(results),
      });
    }
  };

  const clearHandler = (input) => {
    console.log(input);
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setPage(0);
      setInventory((prev) => ({ ...prev, isFetching: true }));
    }, 500),
    [],
  );

  const handleChangeCheckbox = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.checked ? 2 : null });
    debouncedSearchHandler();
  };

  return (
    <>
      <PageTitle title={t("inventory")} />
      <InputContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="sku"
              label={t("product_sku")}
              variant="outlined"
              value={search.product_sku_startswith}
              onChange={handleChangeFilterInput("product_sku_startswith")}
              InputProps={clearAdornment("product_sku_startswith")}
              LeftIcon={QrCode2}
              isProductSKU={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormattedTextField
              fullWidth
              id="location"
              label={t("location")}
              variant="outlined"
              value={search.location__code}
              onChange={handleChangeFilterInput("location__code")}
              InputProps={clearAdornment("location__code")}
              isLocationCode={true}
              LeftIcon={SpaceBar}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Dropdown
              id="location__location_type"
              title={t("location_type")}
              value={search.location__location_type}
              onChange={handleChangeFilterInput("location__location_type")}
              items={LocationTypes}
              LeftIcon={FormatListNumbered}
            />
          </Grid>
          {seller_id === 1 && (
            <>
              <ProductTypeFilter
                value={search.product__product_type}
                setValue={(data) =>
                  setSearch({ ...search, product__product_type: data })
                }
                onChange={handleChangeFilterInput("product__product_type")}
                lg={2}
              />
              <Grid item xs={12} sm={3} md={2} lg={1}>
                <Checkbox
                  label="OBT"
                  checked={search.seller_id}
                  onChange={handleChangeCheckbox("seller_id")}
                  popup="OBT Items"
                />
              </Grid>
            </>
          )}
          <Grid item lg={2} style={{ borderRight: "none" }} />
        </Grid>
      </InputContainer>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DefaultTable
            headers={cells}
            headerCounts={headerCounts}
            data={inventory}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleExportData={handleExportData}
            disableExportData={
              !permissions?.includes("warehouse.export_data.inventory")
            }
            sortActions={{ onSort, orderBy, orderDirection }}
            exportDisabled={exportDisabled}
            setExportDisabled={setExportDisabled}
            filters={search}
          />
        </Grid>
      </Grid>
    </>
  );
}
