import React, { useCallback, useEffect, useMemo, useState } from "react";

import _, { debounce } from "lodash";
import { Box, Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  getReceiptLines,
  getReceiptRelations,
  publishReceipt,
} from "api/receipt";

import { ExternalLink } from "components/Custom/Links";
import { StatusLabel } from "components/Custom";
import { Checkbox, InputContainer } from "components/Form";
import { FormattedTextField } from "components/FormElements";
import DefaultTable from "components/Table";
import PageTitle from "components/PageTitle";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";
import { useUserState } from "context/UserContext";

import { QrCode2 } from "icons";

import { initialAPIListValue } from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { timezoneFormat } from "utils/date";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import { toTitleCase } from "utils/string";

const ADJ_TRF_LINK_PREFIX =
  "https://tayse.acumatica.com/Main?CompanyID=Tayse&ScreenId=GI901322&ContainerNbr=";

const RECEIPT_LINK_PREFIX =
  "https://tayse.acumatica.com/Main?CompanyID=Tayse&ScreenId=PO302000&ReceiptType=RT&ReceiptNbr=";

export default function ReceiptDetail({ location }) {
  const { id } = useParams();
  const { t } = useTranslation();
  const layoutDispatch = useLayoutDispatch();
  const state = location && location.state ? location.state : {};
  let { status, receiptNumber, dockId, container } = state;

  const { permissions } = useUserState();
  const hasPublishPermission =
    status === "In_Progress" && permissions?.includes("receipt.publish");

  const [error, setError] = useState("");
  const [receiptLines, setReceiptLines] = useState(initialAPIListValue);
  const [transfers, setTransfers] = useState(initialAPIListValue);
  const [adjustments, setAdjustments] = useState(initialAPIListValue);
  const [search, setSearch] = useState({
    receipt_id: id,
    product__sku: "",
    shortage: false,
    overage: false,
    published: false,
  });
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [transferDirection, setTransferDirection] = useState("desc");
  const [transferOrderBy, setTransferOrderBy] = useState("created_at");
  const [adjustmentDirection, setAdjustmentDirection] = useState("desc");
  const [adjustmentOrderBy, setAdjustmentOrderBy] = useState("created_at");
  const [disabled, setDisabled] = useState(false);

  const cells = [
    {
      id: "product__sku",
      sortable: true,
      label: "product_sku",
      render: (row) => row.product_sku,
    },
    {
      id: "qty",
      sortable: true,
      info: "Receipt Qty. -> Transferred Qty. (Remaining Qty.)",
      render: (row, exported) => {
        if (exported) {
          return `${row.qty} -> ${row.picked_qty}`;
        }
        const diff = row.qty - row.picked_qty;
        if (row.qty < row.picked_qty) {
          return (
            <StatusLabel
              status="Warning"
              text={`${row.qty} -> ${row.picked_qty} (+${Math.abs(diff)})`}
            />
          );
        } else if (row.qty > row.picked_qty) {
          if (status === "Completed") {
            return (
              <StatusLabel
                status="Cancelled"
                text={`${row.qty} -> ${row.picked_qty} (${diff})`}
              />
            );
          }
          return (
            <StatusLabel
              status="Open"
              text={`${row.qty} -> ${row.picked_qty} (${diff})`}
            />
          );
        } else {
          return (
            <StatusLabel
              status="Completed"
              text={`${row.qty} -> ${row.picked_qty}`}
            />
          );
        }
      },
    },
  ];
  const transferHeaders = [
    {
      id: "product__sku",
      label: "product_sku",
      exportLabel: "Inventory Id",
      sortable: true,
      render: (r, exported) => {
        if (exported) return r.product.sku;
        else {
          const isInReceipt = receiptLines.items.filter(
            (line) => line.product_sku === r.product.sku,
          ).length;
          if (!isInReceipt) {
            return (
              <StatusLabel
                status="Warning"
                text={r.product.sku}
                altText="Not in receipt"
              />
            );
          }
          return <StatusLabel status="Open" text={r.product.sku} />;
        }
      },
    },
    {
      id: "to_location__code",
      label: "location",
      sortable: true,
      render: (r) => r.to_location.code,
    },
    {
      id: "qty",
      exportLabel: "Quantity",
      sortable: true,
      render: (r, exported) =>
        exported ? (
          r.qty
        ) : (
          <StatusLabel
            status={r.is_published ? "Completed" : ""}
            text={r.qty}
            altText={r.is_published ? "Published" : "Not Published"}
          />
        ),
    },
    {
      id: "created_at",
      sortable: true,
      label: "Created",
      render: (r) => (
        <span title={r.userInfo || ""}>{timezoneFormat(r.created_at)}</span>
      ),
    },
  ];
  const adjustmentHeaders = [
    {
      id: "product__sku",
      label: "product_sku",
      exportLabel: "Inventory Id",
      sortable: true,
      render: (r, exported) => {
        if (exported) return r.product.sku;
        else {
          const isInReceipt = receiptLines.items.filter(
            (line) => line.product_sku === r.product.sku,
          ).length;
          if (!isInReceipt) {
            return (
              <StatusLabel
                status="Warning"
                text={r.product.sku}
                altText="Not in receipt"
              />
            );
          }
          return <StatusLabel status="Open" text={r.product.sku} />;
        }
      },
    },
    {
      id: "qty",
      exportLabel: "Quantity",
      sortable: true,
      render: (r, exported) =>
        exported ? (
          r.qty
        ) : (
          <StatusLabel
            status={r.is_published ? "Completed" : ""}
            text={r.qty}
            altText={r.is_published ? "Published" : "Not Published"}
          />
        ),
    },
    {
      id: "created_at",
      sortable: true,
      label: "Created",
      render: (r) => timezoneFormat(r.created_at),
    },
  ];

  // to persist information on page load
  if (!_.every([status, receiptNumber, dockId, container])) {
    status = localStorage.getItem("currentReceiptDetailStatus");
    receiptNumber = localStorage.getItem("currentReceiptDetailNumber");
    dockId = localStorage.getItem("currentReceiptDetailDockId");
    container = localStorage.getItem("currentReceiptDetailContainer");
  } else {
    localStorage.setItem("currentReceiptDetailStatus", status);
    localStorage.setItem("currentReceiptDetailNumber", receiptNumber);
    localStorage.setItem("currentReceiptDetailDockId", dockId);
    localStorage.setItem("currentReceiptDetailContainer", container);
  }

  useEffect(() => {
    if (receiptLines.isFetching) {
      let params = {
        ...search,
        limit: 1000,
      };
      if (orderDirection && orderBy) {
        params.ordering = `${orderDirection === "asc" ? "" : "-"}${orderBy}`;
      }
      getReceiptLines({
        params,
        setError,
        setFunc: setReceiptLines,
        paginated: true,
      });
    }
  }, [search, receiptLines.isFetching, orderBy, orderDirection]);

  useEffect(() => {
    getReceiptRelations({
      setError,
      params: { id },
      responseSetter: (data) => {
        const { transfers, adjustments } = data;
        const grouppedTransfers = transfers
          .filter((t) => t.is_cancelled === false)
          .reduce((acc, t) => {
            const key = `${t.product.sku}|${t.to_location.code}|${t.is_published}`;
            const matchedItem = acc.find((r) => r.key === key);
            const username = toTitleCase(
              t.created_by ? t.created_by.username : "",
            );
            if (matchedItem) {
              matchedItem.qty += t.qty;
              if (!matchedItem.userInfo.includes(username))
                matchedItem.userInfo += `, ${username}`;
            } else {
              acc.push({ key, ...t, userInfo: username });
            }
            return acc;
          }, []);
        const grouppedAdjustments = adjustments
          .filter((a) => a.is_cancelled === false)
          .reduce((acc, a) => {
            const key = `${a.product.sku}|${a.location.code}|${a.is_published}`;
            const matchedItem = acc.find((r) => r.key === key);
            if (matchedItem) {
              matchedItem.qty += a.qty;
            } else {
              acc.push({ key, ...a });
            }
            return acc;
          }, []);
        setTransfers({
          isFetching: false,
          items: grouppedTransfers,
        });
        setAdjustments({
          isFetching: false,
          items: grouppedAdjustments,
        });
      },
    });
  }, [id]);

  useEffect(() => {
    if (error) {
      popupNotification({
        dispatch: layoutDispatch,
        message: error,
      });
      setError(false);
    }
  }, [error, layoutDispatch]);

  const handleChangeFilterInput = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
    debouncedSearchHandler();
  };

  const onSort = (header) => {
    const isAsc = orderBy === header && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(header);
    setReceiptLines({ ...receiptLines, isFetching: true });
  };

  const exportData = (data, prefix, headers) => {
    const today = new Date();
    exportToExcel(
      [],
      `${prefix}-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data, headers),
      setDisabled,
    );
  };

  const handleExportData = (table) => {
    if (table === "details") {
      if (receiptLines.items.length === receiptLines.count) {
        exportData(
          receiptLines.items,
          `ReceiptDetails-${receiptNumber}`,
          cells,
        );
      } else {
        getReceiptLines({
          params: {
            limit: receiptLines.count,
            ...search,
          },
          setFunc: ({ items: { results } }) =>
            exportData(results, `ReceiptDetails-${receiptNumber}`, cells),
        });
      }
    } else if (table === "transfers") {
      exportData(transfers.items, `TransferDetails-${receiptNumber}`, [
        ...transferHeaders,
        { id: "is_published" },
      ]);
    } else if (table === "adjustments") {
      exportData(adjustments.items, `AdjustmentDetails-${receiptNumber}`, [
        ...adjustmentHeaders,
        { id: "is_published" },
      ]);
    } else {
      console.error("Incorrect table type");
    }
  };

  const clearHandler = (input) => {
    setSearch({ ...search, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setReceiptLines({ ...receiptLines, isFetching: true });
    }, 500),
    [],
  );

  const filteredReceiptLines = useMemo(() => {
    if (search.shortage)
      return receiptLines.items.filter((rl) => rl.qty > rl.picked_qty);
    if (search.overage)
      return receiptLines.items.filter((rl) => rl.qty < rl.picked_qty);
    return receiptLines.items;
  }, [search.shortage, search.overage, receiptLines.items]);

  const filteredTransfers = useMemo(() => {
    let results = transfers.items;
    if (search.product__sku)
      results = results.filter((line) =>
        line.product.sku
          .toLowerCase()
          .includes(search.product__sku.toLowerCase()),
      );
    if (search.published) results = results.filter((line) => line.is_published);
    if (search.shortage) {
      const shortageSkus = filteredReceiptLines.map((r) => r.product_sku);
      results = results.filter(
        (line) => shortageSkus.indexOf(line.product.sku) !== -1,
      );
    }
    if (search.overage) {
      const overageSkus = filteredReceiptLines.map((r) => r.product_sku);
      results = results.filter((line) => {
        const isInOverage = overageSkus.indexOf(line.product.sku) !== -1;
        const receiptLine = receiptLines.items.find(
          (rl) => rl.product_sku === line.product.sku,
        );
        return isInOverage || !receiptLine;
      });
    }
    return results;
  }, [
    transfers.items,
    search.product__sku,
    search.published,
    search.shortage,
    search.overage,
    filteredReceiptLines,
    receiptLines.items,
  ]);

  const filteredAdjustments = useMemo(() => {
    let results = adjustments.items;
    if (search.product__sku)
      results = results.filter((line) =>
        line.product.sku
          .toLowerCase()
          .includes(search.product__sku.toLowerCase()),
      );
    if (search.published) results = results.filter((line) => line.is_published);
    if (search.shortage) {
      const shortageSkus = filteredReceiptLines.map((r) => r.product_sku);
      results = results.filter(
        (line) => shortageSkus.indexOf(line.product.sku) !== -1,
      );
    }
    if (search.overage) {
      const overageSkus = filteredReceiptLines.map((r) => r.product_sku);
      results = results.filter((line) => {
        const isInOverage = overageSkus.indexOf(line.product.sku) !== -1;
        const receiptLine = receiptLines.items.find(
          (rl) => rl.product_sku === line.product.sku,
        );
        return isInOverage || !receiptLine;
      });
    }
    return results;
  }, [
    adjustments.items,
    filteredReceiptLines,
    receiptLines.items,
    search.overage,
    search.product__sku,
    search.published,
    search.shortage,
  ]);

  return (
    <>
      <PageTitle
        title={`Receipt ${receiptNumber || "-"} > ${dockId || "-"}`}
        backLink={"/app/receipt"}
        button={
          hasPublishPermission && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={disabled}
              onClick={() => {
                setDisabled(true);
                publishReceipt({
                  id,
                  setError,
                  responseSetter: (response) => {
                    // to prevent multiple syncs
                    setTimeout(() => setDisabled(false), 3000);
                    if (response.success) {
                      popupNotification({
                        dispatch: layoutDispatch,
                        message: response.message,
                        status: "success",
                      });
                      setTimeout(() => window.location.reload(), 2000);
                    } else {
                      popupNotification({
                        dispatch: layoutDispatch,
                        message: response.message,
                        status: "error",
                      });
                    }
                  },
                });
              }}
            >
              Publish
            </Button>
          )
        }
      />
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <InputContainer>
            <Grid container>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <FormattedTextField
                  fullWidth
                  id="sku"
                  label={t("product_sku")}
                  variant="outlined"
                  value={search.product__sku}
                  onChange={handleChangeFilterInput("product__sku")}
                  isProductSKU={true}
                  InputProps={clearAdornment("product__sku")}
                  LeftIcon={QrCode2}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Checkbox
                  checked={search.shortage}
                  onChange={() =>
                    setSearch((prev) => ({ ...prev, shortage: !prev.shortage }))
                  }
                  color="blue"
                  label="Shortage"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Checkbox
                  checked={search.overage}
                  onChange={() =>
                    setSearch((prev) => ({ ...prev, overage: !prev.overage }))
                  }
                  color="blue"
                  label="Overage"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Checkbox
                  checked={search.published}
                  onChange={() =>
                    setSearch((prev) => ({
                      ...prev,
                      published: !prev.published,
                    }))
                  }
                  color="blue"
                  label="Published"
                />
              </Grid>
            </Grid>
          </InputContainer>
          <Grid container spacing={4}>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <Box fontSize="2rem" fontWeight="bold">
                <ExternalLink
                  to={`${RECEIPT_LINK_PREFIX}${receiptNumber}`}
                  children={`Receipt - ${receiptLines.items.reduce(
                    (acc, li) => (acc += li.qty),
                    0,
                  )}`}
                />
              </Box>
              <DefaultTable
                headers={cells}
                data={{
                  items: filteredReceiptLines,
                  isFetching: false,
                  count: filteredReceiptLines.length || 0,
                }}
                handleExportData={() => handleExportData("details")}
                sortActions={{ onSort, orderBy, orderDirection }}
                disabled={disabled}
                setExportDisabled={setDisabled}
                filters={search}
              />
            </Grid>
            {transfers.items && transfers.items.length ? (
              <Grid item lg={5} md={12} sm={12} xs={12}>
                <Box fontSize="2rem" fontWeight="bold">
                  <ExternalLink
                    to={`${ADJ_TRF_LINK_PREFIX}${container}`}
                    children={`Transfers - ${transfers.items.reduce(
                      (acc, ti) => (acc += ti.qty),
                      0,
                    )}`}
                  />
                </Box>
                <DefaultTable
                  headers={transferHeaders}
                  data={{ ...transfers, items: filteredTransfers }}
                  handleExportData={() => handleExportData("transfers")}
                  setExportDisabled={setDisabled}
                  sortActions={{
                    onSort: (header) => {
                      const isAsc =
                        transferOrderBy === header &&
                        transferDirection === "asc";
                      const newDirection = isAsc ? "desc" : "asc";
                      setTransferDirection(newDirection);
                      setTransferOrderBy(header);
                      setTransfers((prev) => ({
                        ...prev,
                        items: _.orderBy(
                          prev.items,
                          [header.split("__").join(".")],
                          [newDirection],
                        ),
                      }));
                    },
                    orderBy: transferOrderBy,
                    orderDirection: transferDirection,
                  }}
                />
              </Grid>
            ) : null}
            {adjustments.items && adjustments.items.length ? (
              <Grid item lg={4} md={12} sm={12} xs={12}>
                <Box fontSize="2rem" fontWeight="bold">
                  <ExternalLink
                    to={`${ADJ_TRF_LINK_PREFIX}${container}&TypeCombo=A`}
                    children={`Adjustments - ${adjustments.items.reduce(
                      (acc, ai) => (acc += ai.qty),
                      0,
                    )}`}
                  />
                </Box>
                <DefaultTable
                  headers={adjustmentHeaders}
                  data={{ ...adjustments, items: filteredAdjustments }}
                  handleExportData={() => handleExportData("adjustments")}
                  setExportDisabled={setDisabled}
                  sortActions={{
                    onSort: (header) => {
                      const isAsc =
                        adjustmentOrderBy === header &&
                        adjustmentDirection === "asc";
                      const newDirection = isAsc ? "desc" : "asc";
                      setAdjustmentDirection(newDirection);
                      setAdjustmentOrderBy(header);
                      setAdjustments((prev) => ({
                        ...prev,
                        items: _.orderBy(
                          prev.items,
                          [header.split("__").join(".")],
                          [newDirection],
                        ),
                      }));
                    },
                    orderBy: adjustmentOrderBy,
                    orderDirection: adjustmentDirection,
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
